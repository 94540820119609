/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react"
import { Card } from "@engaging-tech/components"
import styled from "styled-components"
import TemplateQuestionContext from "./TemplateQuestionContext"
import PreviewBody from "./PreviewBody/PreviewBody"
import QuestionCategory from "./QuestionCategory"
import PopUpMenuControl from "./PopUpMenu/PopUpMenuControl"
import EditBody from "./EditBody/EditBody"
import HomepageContainer from "../containers/BespokeStep/HomepageContainer"

const MainCard = styled(Card)`
  border-radius: 10px;
  margin-top: 30px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.light[0]};
  flex-direction: row;
  height: 270px;
  position: relative;
`

const TemplateQuestion = ({ question, viewOnly }) => {
  const [isEditMode, setIsEditMode] = useState(false)
  const [openBespokeStep, setOpenBespokeStep] = useState(false)
  // avoid rendering error if question not found in global state
  if (!question) return null
  const { type, id } = question

  return (
    <TemplateQuestionContext.Provider
      value={{
        ...question,
        viewOnly,
        isEditMode,
        setOpenBespokeStep,
        openBespokeStep
      }}
    >
      <MainCard>
        {openBespokeStep && <HomepageContainer />}
        {!viewOnly && (
          <PopUpMenuControl
            type={type}
            setIsEditMode={setIsEditMode}
            isEditMode={isEditMode}
            currentQuestion={id}
          />
        )}
        <QuestionCategory />
        {isEditMode ? <EditBody /> : <PreviewBody />}
      </MainCard>
    </TemplateQuestionContext.Provider>
  )
}

export default TemplateQuestion
