import React from "react"
import styled from "styled-components"
import { Text, Box, Paragraph, Flex } from "@engaging-tech/components"
import ReportCard from "../../components/ReportCard"

const image = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/survey_placeholder.png`)

const OverflowBox = styled(Box)`
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
`

const PlaceholderParagraph = styled(Paragraph)`
  font-size: ${({ theme }) => theme.fontSizes[3]};
  width: 100%;
  color: ${({ theme }) => theme.colors.dark[2]};
  text-align: center;
`

const SurveyComparison = ({ exportingPDF }) => (
  <ReportCard
    heading="Compare These Survey Results to Previous Surveys"
    width={[1 / 1, 1 / 1, 59 / 100]}
    exportingPDF={exportingPDF}
    // height="auto"
  >
    <Box position="relative" width={1 / 1}>
      <OverflowBox width={1 / 1}>
        <Box minWidth={["auto", 650]}>
          <img
            width="100%"
            src={image}
            alt="comparison placeholder"
            position="relative"
          />
        </Box>
      </OverflowBox>
      <Flex
        position="absolute"
        top={[0, "25%"]}
        left={[0, "25%"]}
        width={[1 / 1, 1 / 2]}
        flexDirection="column"
        alignItems="center"
      >
        <Text
          fontSize={4}
          fontWeight={500}
          textAlign="center"
          width="auto"
          color="dark.1"
        >
          This is your first survey!
        </Text>
        <PlaceholderParagraph mt={3}>
          When you have completed your next survey, you will be able to track
          and compare your results to previous surveys here.
        </PlaceholderParagraph>
        <PlaceholderParagraph>
          This tool helps you to measure and track your progress and
          improvements as an organisation over time.
        </PlaceholderParagraph>
      </Flex>
    </Box>
  </ReportCard>
)

export default SurveyComparison
