/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import ReportDashboardLoader from "../features/Reports/containers/ReportDashboardLoader"
import DashboardLayout from "../layouts/DashboardLayout"
import ReportsDashboardView from "../features/Reports/views/ReportsDashboard"
import HoldingPage from "../features/Reports/components/HoldingPage"
import { ReportSelectors } from "../features/Reports"
import { OrgGuard } from "../features/account"
import { setSurveyType } from "../features/Reports/store/report.actions"

const ReportsDashboard = () => {
  const isLoading = useSelector(ReportSelectors.getIsAllReportsLoading)
  const isLoadingReports = useSelector(ReportSelectors.getIsAllReportsLoading)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setSurveyType(["organisation", "awards"]))
  }, [])

  return (
    <OrgGuard>
      <DashboardLayout>
        <ReportDashboardLoader />
        {isLoading || isLoadingReports ? (
          <HoldingPage text="Reports" />
        ) : (
          <ReportsDashboardView />
        )}
      </DashboardLayout>
    </OrgGuard>
  )
}

export default ReportsDashboard
