import { gql } from "../../../../../lib/Apollo"

export const LOAD_TEMPLATE = gql`
  query($id: ID!) {
    getSurveyTemplate(id: $id) {
      id
      type
      isDraft
      name
      type
      description
      workFromHome
      bespokeSteps
      questions {
        id
        type
        title
        description
        enabled
        required
        icon
        ... on DropdownQuestion {
          isFilter
          label
          values {
            id
            value
          }
        }
        ... on SmileyFaceQuestion {
          # isFilter
          label
          values {
            id
            value
            smileyValue
          }
        }
        ... on RadioQuestion {
          isFilter
          label
          values {
            id
            value
          }
        }

        ... on ScaleQuestion {
          min
          max
          customStepName
        }
        ... on ShortTextQuestion {
          fields {
            label
            limit
            type
          }
        }
      }
      sections {
        questions
        description {
          type
          content
        }
        visible
        name
      }
    }
  }
`

export const SAVE_ORGANISATION_TEMPLATE = gql`
  mutation(
    $surveyTemplateId: ID
    $surveyTemplate: OrganisationSurveyTemplateInput
  ) {
    saveOrganisationTemplate(
      surveyTemplateId: $surveyTemplateId
      surveyTemplate: $surveyTemplate
    ) {
      id
    }
  }
`
