import { Button, Icon } from "@engaging-tech/components"
import React from "react"
import styled from "styled-components"

const Container = styled.div`
  position: relative;
`

const UnlockDiv = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  justify-content: center;
  align-items: "center";
  background-color: ${({ theme }) => theme.colors.secondary[0]};
  padding: 40px 30px;
`

const ImageResponsive = styled.img`
  width: 100%;
  height: auto;
  min-height: 217px;
`

export function ImageLocked({ imageName, onClick, unlockText }) {
  return (
    <Container>
      <UnlockDiv>
        <Icon name="lock" color="white" size={"73"} mb={"5"} />
        <Button onClick={onClick} color="black" fontWeight={700} width={200} fontSize={"3"}>
          {unlockText}
        </Button>
      </UnlockDiv>
      <ImageResponsive src={require(`${process.env.RAZZLE_PUBLIC_DIR}/img/${imageName}.png`)} />
    </Container>
  )
}
