import React from "react"
import {
  Text,
  Flex,
  Box,
  Avatar,
  MobileOnly,
  TabletUp
} from "@engaging-tech/components"
import { Link } from "@engaging-tech/routing"
import styled from "styled-components"
import Carousel from "../../ui/components/Carousel"

const BorderedBox = styled(Box)`
  border: 1px solid #046a8b;
  border-radius: 4px;
  &:hover {
    cursor: "pointer";
  }
`

const profilePlaceholder = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/No_Photo_Empty_State.svg`)

const Res = ({ id, title, user }) => {
  return (
    <Link to={`network/research/${id}`}>
      <BorderedBox
        maxWidth="210px"
        minWidth="200px"
        minHeight="200px"
        height="100%"
        mr={[0, 2, 3]}
        mb={[2, 0, 0]}
      >
        <Flex
          py={2}
          px={[3, 2, 3]}
          justifyContent="space-between"
          flexDirection="column"
          width={1 / 1}
          height="100%"
        >
          <Flex mb={2} minHeight="60px">
            <Avatar
              picture={user?.profile?.picture?.small || profilePlaceholder}
              mr={2}
            />
            <Flex flexDirection="column">
              <Text>
                {user?.firstName} {user?.lastName}
              </Text>
            </Flex>
          </Flex>
          <Text mb={1} fontWeight={700} fontSize={[3, 4, 4]}>
            {title.slice(0, 90)}
            {title.length > 90 && <Text>...</Text>}
          </Text>
        </Flex>
      </BorderedBox>
    </Link>
  )
}

const Research = ({ research }) => {
  return (
    <>
      {research && (
        <>
          <TabletUp width={1 / 1}>
            <Carousel
              carouselItems={research}
              numberOfItems={3}
              componentToShow={Res}
            />
          </TabletUp>
          <MobileOnly width={1 / 1}>
            <Carousel
              carouselItems={research}
              numberOfItems={1}
              componentToShow={Res}
            />
          </MobileOnly>
        </>
      )}
    </>
  )
}

export default Research
