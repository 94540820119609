import { connect } from "react-redux"
import { getOrgId } from "../../../account/store/account.selectors"
import { getQuestionScore } from "../../SixStepsQuestions/store/SixStepsQuestions.selectors"
import PageManagementComponent from "../components/SixStepsPagesComponents/PageManagement"

const mapState = (state, { step }) => ({
  stepQuestions: getQuestionScore(state, step),
  orgId: getOrgId(state)
})

const PageManagement = connect(mapState)(PageManagementComponent)

export default PageManagement
