import { titleCaps } from "@engaging-tech/frontend-utils"
import { types } from "./account.actions"

export const initialState = {
  isAuthenticated: false,
  hasResetPassword: false,
  hasError: false,
  shouldClose: false,
  token: "",
  isLoading: false,
  isAuthenticationLoading: false,
  errorCode: null,
  hasForgotPassword: false,
  isUploading: false,
  formNav: {
    activeCrumb: "Business Information"
  },
  profileId: null,
  checkout: { intentSecret: null, hasPaid: false },
  discount: { isLoading: false },
  accountPreferences: {
    errors: { general: "", error: false }
  },
  organisation: {
    accountPreferences: {}
  },
  magicLinkSent: false
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_ACCOUNT_PREFERENCES_SUCCESS: {
      return {
        ...state,
        accountPreferences: {
          errors: { general: "", error: null }
        },
        organisation: {
          ...state.organisation,
          accountPreferences: {
            ...state.organisation.accountPreferences,
            ...action.payload.updateAccountPreferences
          }
        }
      }
    }

    case types.UPDATE_ACCOUNT_PREFERENCES_FAIL: {
      return {
        ...state,
        accountPreferences: {
          errors: { error: action.payload }
        }
      }
    }

    case types.CONFIRM_EMAIL:
    case types.REQUEST_CONFIRMATION:
    case types.TOKEN_SIGN_IN:
      return {
        ...state,
        isAuthenticated: false,
        isAuthenticationLoading: false,
        hasError: action.payload
      }

    case types.LOAD:
    case types.CREATE_INTENT:
    case types.UPDATE_ORGANISATION:
    case types.PUBLISH_BIP:
      return { ...state, isLoading: true }

    case types.SET_HAS_ERROR:
      return {
        ...state,
        isLoading: false,
        hasError: action.payload
      }

    case types.PUBLISH_BIP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        profileId: action.payload
      }

    case types.CLOSE_FORM:
      return {
        ...state,
        shouldClose: true
      }

    case types.RESET_CLOSE_FORM:
      return {
        ...state,
        shouldClose: false
      }

    case types.SIGN_IN_SUCCESS:
    case types.SIGN_UP_SUCCESS:
      return {
        ...state,
        isAuthenticationLoading: false,
        isLoading: false,
        isAuthenticated: true,
        token: action.payload.token,
        organisation: action.payload.organisation,
        errorCode: ""
      }

    case types.SET_ACTIVE_CRUMB:
      return {
        ...state,
        formNav: {
          activeCrumb: action.payload
        }
      }
    case types.LOAD_SUCCESS:
    case types.UPDATE_ORGANISATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        organisation: { ...state.organisation, ...action.payload }
      }
    case types.CONFIRM_EMAIL_SUCCESS:
      return {
        ...state,
        organisation: { ...state.organisation, confirmed: true }
      }
    case types.REQUEST_CONFIRMATION_SUCCESS:
      return { ...state, isLoading: false, isAuthenticationLoading: false }

    case types.CREATE_INTENT_SUCCESS:
      return {
        ...state,
        isLoading: true,
        checkout: {
          ...state.checkout,
          intentSecret: action.payload.intentSecret,
          status: action.payload.status
        }
      }
    case types.UPGRADE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        checkout: { ...state.checkout, hasPaid: true, intentSecret: null },
        organisation: {
          ...state.organisation,
          services: [
            ...state.organisation.services,
            {
              name: "ebn",
              active: true
            }
          ]
        }
      }

    case types.SEND_MAGIC_LINK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isAuthenticationLoading: false,
        magicLinkSent: true
      }

    case types.SEND_MAGIC_LINK_FAIL:
    case types.SIGN_IN_FAIL:
    case types.TOKEN_SIGN_IN_FAIL:
    case types.SIGN_UP_FAIL:
    case types.FORGOT_PASSWORD_FAIL:
    case types.RESET_PASSWORD_FAIL:
    case types.CREATE_INTENT_FAIL:
      return {
        ...state,
        isLoading: false,
        isAuthenticationLoading: false,
        magicLinkSent: false,
        errorCode: action.payload
      }

    case types.CONFIRM_EMAIL_FAIL:
      return {
        ...state,
        organisation: { ...state.organisation, confirmed: false }
      }
    case types.LOAD_FAIL:
      return {
        ...state,
        organisation: null,
        isLoading: false,
        isAuthenticationLoading: false,
        errorCode: action.payload
      }

    case types.REQUEST_CONFIRMATION_FAIL:
      return { ...state, isLoading: false, isAuthenticationLoading: false }

    case types.UPGRADE_FAIL:
      return {
        ...state,
        checkout: { ...state.checkout, hasPaid: false, intentSecret: "" }
      }

    case types.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isAuthenticationLoading: false,
        hasForgotPassword: true
      }

    case types.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isAuthenticationLoading: false,
        hasResetPassword: true
      }

    case types.SIGN_OUT_SUCCESS:
      return {
        ...state,
        token: "",
        isAuthenticated: false,
        isLoading: false,
        isAuthenticationLoading: false
      }

    case types.UPDATE_CURRENT_CREDITS:
      return {
        ...state,
        organisation: {
          ...state.organisation,
          credits: state.organisation.credits + action.payload
        }
      }

    case types.SAVE_LOCATION:
      return {
        ...state,
        organisation: {
          ...state.organisation,
          businessInformation: {
            ...state.organisation.businessInformation,
            locations: [
              ...state.organisation.businessInformation.locations,
              {
                id: `location-${Date.now()}`,
                country: { id: action.payload.countryId },
                city: titleCaps(action.payload.city)
              }
            ]
          }
        }
      }

    case types.DELETE_LOCATION:
      return {
        ...state,
        organisation: {
          ...state.organisation,
          businessInformation: {
            ...state.organisation.businessInformation,
            locations: state.organisation.businessInformation.locations.filter(
              location => location.id !== action.payload.id
            )
          }
        }
      }

    case types.UPLOAD_IMAGE:
      return {
        ...state,
        isUploading: true
      }

    case types.UPLOAD_IMAGE_SUCCESS:
      return {
        ...state,
        isUploading: false,
        organisation: {
          ...state.organisation,
          businessInformation: {
            ...state.businessInformation,
            logoUrl: action.payload
          }
        }
      }

    case types.APPLY_DISCOUNT:
      return {
        ...state,
        discount: {
          isLoading: true,
          code: action.payload.discountCode,
          service: action.payload.service
        },
        errorCode: null
      }

    case types.APPLY_DISCOUNT_SUCCESS:
      return {
        ...state,
        discount: {
          code: state.discount.code,
          service: state.discount.service,
          isLoading: false,
          ...action.payload
        }
      }

    case types.APPLY_DISCOUNT_FAILURE:
      return {
        ...state,
        discount: { isLoading: false },
        errorCode: action.payload
      }

    case types.RESET_DISCOUNT_STATE:
      return {
        ...state,
        discount: initialState.discount
      }

    case types.RESET_SEND_MAGIC_LINK:
      return {
        ...state,
        isLoading: false,
        isAuthenticationLoading: false,
        magicLinkSent: false
      }
    default:
      return state
  }
}
