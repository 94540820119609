import { Flex, Text } from "@engaging-tech/components"
import React from "react"
import styled from "styled-components"

import ActionButtonCard from "./ActionButtonCard"

const CustomFlex = styled(Flex)`
  grid-area: ${({ i, device }) => (i !== 0 && device !== "MOBILE" ? "B" : "C")};
  height: fit-content;
  flex-direction: column;
`

const Img = styled.img`
  width: 60px;
`

const Resource = ({ resource: { type, name, thumbnail, author, id, link } }) => {
  const localText = link
    ? `<span style="color: rgba(0, 0, 0, 0.6);text-transform: capitalize;">${type} - </span> <a href="${link}" target="_blank"><u>${name}</u></a> ${author}`
    : `<span style="color: rgba(0, 0, 0, 0.6);text-transform: capitalize;">${type} - </span> <a href="https://app.workl.co/business-library/browse/${type}s/${id}/" target="_blank"><u>${name}</u></a> ${author}`

  return (
    <Flex mb={3} alignItems="center">
      <Img src={thumbnail} />
      <Text dangerouslySetInnerHTML={{ __html: localText }} ml={2} color="#003E4C" />
    </Flex>
  )
}

const Resources = ({ resources, i, device }) => (
  <CustomFlex i={i} device={device} mt={[4, 0]}>
    <Flex width="100%" bg="#003E4C1F">
      <Flex width="95%" flexDirection="column" py={3} alignItems="flex-end">
        <Text mb={3}>Resources</Text>
        <Text mb={5}>Use the list below to discover materials that could be helpful on this topic</Text>
        {resources?.map(resource => (
          <Resource resource={resource} key={resource.id} />
        ))}
      </Flex>
    </Flex>
    <Flex flexDirection="column" mt={4} height="fit-content">
      <ActionButtonCard
        text="Find many more relevant materials in our Resource Centre."
        buttonText="resource centre"
        link="business-library"
      />
      <ActionButtonCard
        text="Match with a Mentor using our unique Mentor Matching service."
        buttonText="mentor matcher"
        link="develop-career#mentor-matcher"
      />
    </Flex>
  </CustomFlex>
)

export default Resources
