import React, { useRef, useEffect } from "react"
import { Button, CustomHooks } from "@engaging-tech/components"
import ExternalReportItemsRender from "./ExternalReportItemsRender"
import generatePDF from "../helpers/generatePDF"

const exportTypes = {
  print: "PRINT",
  download: "DOWNLOAD"
}

// The print button is always present, but if on safari or mobile/tablet, then it will use normal printing process. Otherwise it would perform PDF export and open in print mode
// The PDF export button is not present on safari or mobile/tablet.

const DownloadPDF = ({
  title,
  subSection,
  isPdfDownloading,
  filters,
  updatePdfDownloading,
  reportComponents,
  updatePDFProgress,
  surveyType
}) => {
  const downloadingRef = useRef(isPdfDownloading)
  const theme = CustomHooks.useStyledTheme()

  const device = CustomHooks.useClientDevice()

  useEffect(() => {
    downloadingRef.current = isPdfDownloading
  }, [isPdfDownloading])

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

  const handleClick = ({ attemptNumber = 1, exportType }) => {
    updatePdfDownloading(true)
    const reportItems = document.querySelectorAll(".report-item-exporting")

    if (
      reportItems.length > 0 &&
      reportItems[0].offsetWidth === 1000 &&
      downloadingRef.current &&
      attemptNumber !== 1
    ) {
      if (typeof window !== "undefined") {
        window.scroll(0, 0)
      }
      if (isSafari || device !== "DESKTOP") {
        setTimeout(() => {
          updatePdfDownloading(false)
        }, 1000)

        window.print()
      } else {
        generatePDF({
          pages: reportItems,
          theme,
          downloadingRef,
          updatePdfDownloading,
          title,
          exportType,
          subSection,
          filters,
          updatePDFProgress
        })
      }
    } else if (attemptNumber < 5) {
      setTimeout(
        () =>
          handleClick({
            attemptNumber: attemptNumber + 1,
            exportType
          }),
        1000
      )
    } else {
      updatePdfDownloading(false)
    }
  }

  const handleClickOnePager = async ({ attemptNumber = 1, exportType }) => {
    updatePdfDownloading(true)

    const reportItems = document.querySelectorAll(".one-page-exporting")

    if (downloadingRef.current && attemptNumber !== 1) {
      if (typeof window !== "undefined") {
        window.scroll(0, 0)
      }
      if (isSafari || device !== "DESKTOP") {
        setTimeout(() => {
          updatePdfDownloading(false)
        }, 1000)

        window.print()
      } else {
        generatePDF({
          pages: reportItems,
          theme,
          downloadingRef,
          updatePdfDownloading,
          title,
          exportType,
          subSection,
          filters,
          updatePDFProgress
        })
      }
    } else if (attemptNumber < 5) {
      setTimeout(
        () =>
          handleClickOnePager({
            attemptNumber: attemptNumber + 1,
            exportType
          }),
        1000
      )
    } else {
      updatePdfDownloading(false)
    }
  }

  return (
    <>
      {isPdfDownloading && (
        <ExternalReportItemsRender reportComponents={reportComponents} />
      )}
      <Button
        variant="outline.secondary.0"
        minWidth={[175, 200]}
        color="secondary.0"
        leadingIcon={isSafari ? "print" : "cloud_download"}
        fontSize={[2, 3]}
        elevation={0}
        m="6px"
        px={3}
        fontWeight={500}
        onClick={() => {
          handleClick({
            exportType: exportTypes.print
          })
        }}
      >
        Print
      </Button>

      {!isSafari && device === "DESKTOP" && (
        <>
          <Button
            variant="outline.secondary.0"
            minWidth={[175, 200]}
            color="secondary.0"
            leadingIcon="cloud_download"
            fontSize={[2, 3]}
            m="6px"
            elevation={0}
            mt={[0, 2]}
            px={3}
            fontWeight={500}
            onClick={() => {
              handleClick({
                exportType: exportTypes.download
              })
            }}
          >
            Download PDF
          </Button>
          {(surveyType.includes("organisation") ||
            surveyType.includes("awards")) && (
            <Button
              variant="outline.secondary.0"
              minWidth={[175, 200]}
              color="secondary.0"
              leadingIcon="cloud_download"
              fontSize={[2, 3]}
              m="6px"
              elevation={0}
              mt={[0, 2]}
              px={3}
              fontWeight={500}
              onClick={() => {
                handleClickOnePager({
                  exportType: exportTypes.download
                })
              }}
            >
              One Page Report
            </Button>
          )}
        </>
      )}
    </>
  )
}
export default DownloadPDF
