import { connect } from "react-redux"
import { getRecipients } from "../../../store/selectors"
import EmailInvite from "../../../components/Page2/Participants/EmailInvite"

const mapState = state => ({
  emailLength: getRecipients(state).length
})

const EmailInviteContainer = connect(mapState, null)(EmailInvite)

export default EmailInviteContainer
