import React from "react"
import { Text, Box, Flex, Icon, Spinner } from "@engaging-tech/components"

import Post from "../Post"

const WebinarCard = ({ webinar }) => {
  if (!webinar) return <Spinner color="primary.0" />
  if (webinar)
    return (
      <Flex width={1 / 1} justifyContent="center">
        <Post.Card bg="light.0">
          <Post.Header bg="secondary.1" mb={3} title="Webinar" />
          <Post.Content>
            <Box mb={2}>
              <Text mb={2} fontWeight="600">
                {webinar.title}
              </Text>
            </Box>
            <iframe
              title={webinar.title}
              width="100%"
              height="315"
              src={webinar.video}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
            {webinar.summary && (
              <Box mt={3}>
                <Text fontWeight="500">{webinar.summary}</Text>
              </Box>
            )}
            {webinar?.date && (
              <Flex mt={3}>
                <Icon name="insert_invitation" />
                <Text ml={2}>{webinar.date}</Text>
              </Flex>
            )}
          </Post.Content>
        </Post.Card>
      </Flex>
    )
}

export default WebinarCard
