import { Flex, Text, Utils } from "@engaging-tech/components"
import { usePaths } from "@engaging-tech/routing"
import React from "react"

import LinkToBtn from "../../surveyManagement/components/LinkToBtn"
import LoadingState from "../../surveyManagement/components/LoadingState"
import SurveyBar from "../../surveyManagement/surveySelection/components/survey-bar"

const { errorTypes } = Utils.coreStore

const EmptyState = () => {
  const paths = usePaths()
  return (
    <Flex
      width="100%"
      justifyContent="center"
      alignItems="center"
      height="50vh"
      maxHeight={250}
      flexDirection="column"
    >
      <Text width="auto" fontSize="20px" mb={2} style={{ textAlign: "center" }}>
        You currently have no surveys to show here
      </Text>
      <Text width="auto" fontSize="16px" mb={5} style={{ textAlign: "center" }}>
        Start gathering insights into your employees by creating a survey
      </Text>
      <LinkToBtn path={paths.surveys.surveyCreation.index} text="Create Survey" />
    </Flex>
  )
}

const ErrorState = ({ message }) => {
  return (
    <Flex
      width="100%"
      justifyContent="center"
      alignItems="center"
      height="50vh"
      maxHeight={200}
      flexDirection="column"
    >
      <Text width="auto" fontSize="20px" mb={2} style={{ textAlign: "center" }}>
        Something went wrong!
      </Text>
      <Text width="auto" fontSize="16px" mb={5} style={{ textAlign: "center" }}>
        {message}
      </Text>
    </Flex>
  )
}

const ListSurveys = ({ isLoading, surveysError, surveysToShow, minimized }) => {
  if (isLoading) {
    return <LoadingState message="Loading surveys" />
  }

  if (surveysError.type !== errorTypes.none) {
    return <ErrorState message={surveysError.message} />
  }
  if (surveysToShow.length === 0) {
    return <EmptyState />
  }

  return (
    <Flex flexDirection="column" width={1 / 1} alignItems="center">
      {surveysToShow?.map((survey, index) => (
        <SurveyBar key={survey.id} index={index} {...survey} id={survey.id} />
      ))}
    </Flex>
  )
}

export default ListSurveys
