import { Flex, Text } from "@engaging-tech/components"
import React from "react"
import styled from "styled-components"

const ImageStyled = styled.img`
  width: 252px;
  border-radius: ${({ active }) => (active ? "20px" : "0")};
  border: 5px solid;
  border-color: ${({ active, theme }) => (active ? theme.colors.primary[0] : "transparent")};
`

const IntroStepsIlustrationsItem = ({ active, description, imagePath, stepNumber, title }) => {
  const image = require(`${process.env.RAZZLE_PUBLIC_DIR}/${imagePath}`)

  return (
    <Flex flexDirection={["column"]} mt={5} width={257}>
      <Flex width={257} flexDirection={["column"]} mb={1}>
        <Text fontSize={4} color={"secondary.0"} fontWeight={active ? "600" : "400"} textAlign="center">
          {stepNumber}. {title}
        </Text>
      </Flex>
      <ImageStyled src={image} alt={`${title} illustration`} active={active} />
      <Text fontSize={3} mt={1} textAlign="center" color={"secondary.0"}>
        {description}
      </Text>
    </Flex>
  )
}

export default IntroStepsIlustrationsItem
