import React from "react";
import BespokeStep from "../7thStep/container/BespokeStep";
import SixStepsActionPlans from "../ActionPlan/components/SixStepsActionPlans";
import CustomQuestions from "../CustomQuestions/containers/CustomQuestions";
import HappinessDataHighlightOverview from "../DataHighlights/components/HappinessDataHighlightOverview";
import DataHighlights from "../DataHighlights/containers/DataHighlights";
import DiversityAndInclusionIndicator from "../DataHighlights/containers/DiversityAndInclusionIndicator";
import FlightRiskIndicator from "../DataHighlights/containers/FlightRiskIndicator";
import ManagementConfidenceIndicator from "../DataHighlights/containers/ManagementConfidenceIndicator";
import WellBeingIndicator from "../DataHighlights/containers/WellBeingIndicator";
import DiversityAndInclusionBreakdown from "../Indicators/containers/DiversityAndInclusionBreakdown";
import FlightRisk from "../Indicators/containers/FlightRiskBreakdown";
import InstantActionPlanning from "../InstantActionPlanning/components/InstantActionPlanning";
import SixStepsPages from "../InstantActionPlanning/components/SixStepsPages";
import LowestScore from "../LowestScore/containers/LowestScore";
import Matrix from "../Matrices/containers/Matrix";
import MatrixFirst from "../Matrices/containers/MatrixFirst";
import NetPromoterScore from "../NetPromoterScore/containers/NetPromoterScore";
import QuestionsBreakdown from "../QuestionsBreakdown/containers/QuestionsBreakdown";
import SentimentAnalysis from "../SentimentAnalysis/containers/SentimentAnalysis";
import SixSteps from "../SixSteps/containers/SixSteps";
import SixStepsBreakdown from "../SixStepsBreakdown/components/SixStepsBreakdown";
import SixStepsComparison from "../SixStepsComparison/containers/SixStepsComparison";
import SixStepsOverview from "../SixStepsOverview/components/SixStepsOverview";
import SixStepsQuestions from "../SixStepsQuestions/components/SixStepsQuestions";
import SurveyComparison from "../SurveyComparison/components/SurveyComparison";

export const getComponents = props => {
  return {
    default: <></>,
    happinessDataHighlight: (
        <DataHighlights heading="Your Data Highlights" hasLink {...props} />
    ),
    surveyComparison: <SurveyComparison {...props} />,

    lowestStep: <LowestScore {...props} />,
    instantActionPlanning: <InstantActionPlanning />,
    happinessDataHighlightOverview: (
        <HappinessDataHighlightOverview {...props} />
    ),
    indicators: (
        <>
          <FlightRiskIndicator {...props} />
          <WellBeingIndicator {...props} />
          <ManagementConfidenceIndicator {...props} />
          <DiversityAndInclusionIndicator {...props} />
        </>
    ),
    netPromoterScore: <NetPromoterScore {...props} />,
    sentimentAnalysis: <SentimentAnalysis {...props} />,
    sixStepsScores: <SixSteps {...props} />,
    sixStepsComparison: <SixStepsComparison {...props} />,
    customQuestions: <CustomQuestions {...props} />,
    sixStepsOverview: <SixStepsOverview {...props} />,
    sixStepsMorrisonsOverview: (
        <SixStepsOverview
            actionPlanGroup="morrisons"
            disableQuestions
            {...props}
        />
    ),
    sixStepsBreakdown: <SixStepsBreakdown {...props} />,
    sixStepsQuestions: <SixStepsQuestions {...props} />,
    sixStepsActionPlans: <SixStepsPages {...props} />,
    sixStepsMorrisonActionPlans: (
        <SixStepsActionPlans actionPlanGroup="morrisons" {...props} />
    ),
    bespokeStep: <BespokeStep {...props} />,
    matrices: <MatrixFirst {...props} />,
    matrices_jobSatisfaction: (
        <Matrix name="matrices_jobSatisfaction" {...props} />
    ),
    matrices_organisationSatisfaction: (
        <Matrix name="matrices_organisationSatisfaction" {...props} />
    ),
    matrices_wellBeing: <Matrix name="matrices_wellBeing" {...props} />,
    matrices_careerDevelopment: (
        <Matrix name="matrices_careerDevelopment" {...props} />
    ),
    matrices_inclusiveness: <Matrix name="matrices_inclusiveness" {...props} />,
    matrices_empowerment: <Matrix name="matrices_empowerment" {...props} />,
    matrices_senseOfPurpose: (
        <Matrix name="matrices_senseOfPurpose" {...props} />
    ),
    matrices_lineManagerRelationship: (
        <Matrix name="matrices_lineManagerRelationship" {...props} />
    ),
    matrices_workplaceEnvironment: (
        <Matrix name="matrices_workplaceEnvironment" {...props} />
    ),
    matrices_managementConfidence: (
        <Matrix name="matrices_managementConfidence" {...props} />
    ),
    matrices_wellBeingRiskIndex: (
        <Matrix name="matrices_wellBeingRiskIndex" {...props} />
    ),
    diIndexBreakdown: <DiversityAndInclusionBreakdown {...props} />,
    flightRiskBreakdown: <FlightRisk {...props} />,
    questionsBreakdown: <QuestionsBreakdown {...props} />,
    coreQuestionsDetails: <CustomQuestions {...props} />
  };
};

const ReportElementsLayout = ({ componentTags, tabs = [], ...props }) => {
  const components = getComponents(props);

  const query = new URLSearchParams(location.search);
  const activeTab = query.get("tab") || (tabs.length > 0 ? tabs[0].title : "");

  const getCurrentTabTags = () => {
    if (!tabs || tabs.length === 0) return componentTags;
    const currentTab = tabs.find(tab => tab.title === activeTab);
    return currentTab ? currentTab.matches : [];
  };

  const currentTags = getCurrentTabTags();

  return (
      <>
        {currentTags.map(tag => {
          const matchedComponentTag = components[tag];
          if (!matchedComponentTag) return null;
          return (
              <React.Fragment key={tag}>
                {React.cloneElement(matchedComponentTag, { componentTags, tag })}
              </React.Fragment>
          );
        })}
      </>
  );
};

export default ReportElementsLayout;
