import React from "react"
import { IconButton } from "@engaging-tech/components"
import styled from "styled-components"

const MarginedIconButton = styled(IconButton)`
  margin-right: 8px;
`

const DeleteButton = ({ onClick }) => {
  return (
    <MarginedIconButton
      icon="delete"
      color="dark.2"
      size={22}
      onClick={onClick}
    />
  )
}

export default DeleteButton
