import { CircularProgress, Text, Flex } from "@engaging-tech/components"
import React from "react"
import styled from "styled-components"
import { getLabelData } from "../../../components/ScoreLabel"

const CustomFlex = styled(Flex)`
  grid-area: B;
  justify-content: center;
  flex-wrap: wrap;
`

const CircularScore = ({ score }) => (
  <CustomFlex>
    <Text textAlign="center" mb={3}>
      Your overall score for this area:
    </Text>
    <CircularProgress
      percentage={score}
      width="50%"
      strokeColor={getLabelData(score).color}
    />
  </CustomFlex>
)

export default CircularScore
