import React from "react"
import { Provider as StoreProvider } from "react-redux"
import { hydrate } from "react-dom"
import { createGlobalStyle, ThemeProvider } from "styled-components"
import { ClientRouter } from "@engaging-tech/routing"
import { Themes } from "@engaging-tech/components"
import { getConfig } from "@engaging-tech/ssr-config"
import { PostHogProvider } from "posthog-js/react"
import App from "./App"
import store from "./store"
import PostHogPageviewTracker from "./PostHogPageviewTracker"

/* eslint-disable import/no-dynamic-require */
const woffIcons = require(`${process.env.RAZZLE_PUBLIC_DIR}/fonts/EngagingTechIcons.woff`)
const ttfIcons = require(`${process.env.RAZZLE_PUBLIC_DIR}/fonts/EngagingTechIcons.ttf`)
const centraWoff = require(`${process.env.RAZZLE_PUBLIC_DIR}/fonts/CentraNo2-Book.woff`)
const centraWoff2 = require(`${process.env.RAZZLE_PUBLIC_DIR}/fonts/CentraNo2-Book.woff2`)
const centraBoldWoff = require(`${process.env.RAZZLE_PUBLIC_DIR}/fonts/CentraNo2-Bold.woff`)
const centraBoldWoff2 = require(`${process.env.RAZZLE_PUBLIC_DIR}/fonts/CentraNo2-Bold.woff2`)
const centraLightWoff = require(`${process.env.RAZZLE_PUBLIC_DIR}/fonts/CentraNo2-Light.woff`)
const centraLightWoff2 = require(`${process.env.RAZZLE_PUBLIC_DIR}/fonts/CentraNo2-Light.woff2`)
/* eslint-enable import/no-dynamic-require */

const GlobalStyles = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500|Roboto+Slab:300,400,700');

  html {
    background-color: rgb(255, 255, 255);
  }

  @media print {

  @page {size: A4 landscape; }
    #app-wrapper {
      display: none;
    }
    #cancel-pdf-modal-overlay {
      display: none;
    }

  img { height: 90%; margin: 0; padding: 0; }	
  }

  /* #fc_frame {
    left: calc(100vw - 100px) !important;
    top: calc(100vh - 100px) !important;
  } */

  @font-face {
    font-family: "EngagingTechIcons";
    font-style: normal;
    font-weight: 400;
    src: 
      url(${woffIcons}) format("woff"),
      url(${ttfIcons}) format("truetype");
  }

  * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  @font-face {
   font-family: "Centra";
   src: url(${centraWoff}) format("woff"),
        url(${centraWoff2}) format("woff2");
        
   font-weight: 400;
   font-style: normal;
  }
  @font-face {
   font-family: "Centra";
   src: url(${centraBoldWoff}) format("woff"),
        url(${centraBoldWoff2}) format("woff2");
        
   font-weight: 700;
   font-style: normal;
  }
  @font-face {
   font-family: "Centra";
   src: url(${centraLightWoff}) format("woff"),
        url(${centraLightWoff2}) format("woff2");
        
   font-weight: 300;
   font-style: normal;
  }

  body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    ${
      "" /* margin-right: ${() =>
      `${
        isScrollbarPresent() ? Utils.measureScrollbarWidth() : 0
      }px`} !important; */
    }
  
  }

  main {
    position: relative;
  }

  a {
    color: inherit;
    text-decoration: inherit;
  }

  hr {
    border-style: none;
  }
`

const BaseApp = () => {
  const config = getConfig()
  const options = {
    api_host: config.posthog.api_host,
    ui_host: config.posthog.ui_host
  }
  return (
    <PostHogProvider apiKey={config.posthog.key} options={options}>
      <StoreProvider store={store}>
        <ClientRouter>
          <PostHogPageviewTracker />
          <ThemeProvider theme={Themes.workLForBusiness}>
            <>
              <GlobalStyles />
              <App />
            </>
          </ThemeProvider>
        </ClientRouter>
      </StoreProvider>
    </PostHogProvider>
  )
}
hydrate(<BaseApp />, document.getElementById("root"))

if (module.hot) {
  module.hot.accept()
}
