const retailTrustOrganisations = [
  "618a48dd215b0c0011937ef6",
  "63f8b225cd2d8b0012f1e79f",
  "630e65e5f340b500182ed158",
  "63f8b13dcd2d8b0012f1e74b",
  "63efac690258020019bce323",
  "6409d38bcd2d8b0012f35750",
  "6436d531cd2d8b0012f7086e",
  "63efb1bb0258020019bce486",
  "6409d431cd2d8b0012f35765",
  "63efb66c0258020019bce5b8",
  "64636c9fbacddd00190cd36f",
  "63f8acc6cd2d8b0012f1e56f",
  "63efa7b30258020019bce186",
  "63f8b3becd2d8b0012f1e87b",
  "5f47b66062346c001712aca4",
  "63f8aa5dcd2d8b0012f1e430",
  "64639935bacddd00190cd7fb",
  "5e83468d99ac410032a8f80b",
  "63efaf090258020019bce3aa",
  "63f8af95cd2d8b0012f1e65d",
  "6553635201af710012638913",
  "63efb0230258020019bce3f3",
  "6436d3b7cd2d8b0012f707e9",
  "63efa7b30258020019bce186",
  "630e65e5f340b500182ed158",
  "63efac2a0258020019bce307",
  "63f8ab43cd2d8b0012f1e4a0"
]

export default retailTrustOrganisations
