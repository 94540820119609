import { Button, Flex, userInterfaceNotificationsStore } from "@engaging-tech/components"
import { getConfig } from "@engaging-tech/ssr-config"
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline"
import React from "react"
import { useDispatch } from "react-redux"
import { Tooltip } from "react-tooltip"
import styled from "styled-components"

const copy = require("copy-text-to-clipboard")

const CustomButton = styled(Button)`
  width: fit-content;

  display: flex;
  flex-direction: row-reverse;

  & > p {
    margin-left: 8px;
  }
`

const CustomIconButton = styled(Button)`
  margin-left: 10px;

  border-radius: 20px;
`

export default function SurveyActions({ id, status, isDetailsOpen, onChangeIsDetailsOpan }) {
  const orgSurvey = `${getConfig().workL.app}/organisationSurvey/${id}`

  const dispatch = useDispatch()

  const handleCopy = () => {
    const isCopySuccessful = copy(orgSurvey)

    if (!isCopySuccessful) {
      dispatch(
        userInterfaceNotificationsStore.actions.addNotification({
          message: `Either Your browser does not support copy to clipboard functionality or something else went wrong. Please copy this link directly: ${link}`
        })
      )
    } else {
      dispatch(
        userInterfaceNotificationsStore.actions.addNotification({
          message: "Your survey link has been copied to clipboard!"
        })
      )
    }
  }

  return (
    <Flex justifyContent="center" alignItems="center" width="100%">
      <Flex width="fit-content" justifyContent="right">
        <CustomButton
          trailingIcon="content_copy"
          onClick={() => {
            if (status === "draft" || status === "schedule") {
              window.open(orgSurvey, "_blank")
            } else {
              handleCopy()
            }
          }}
          data-tooltip-id="survey-copy-button-tooltip"
          data-tooltip-content="Click here to copy the link to your clipboard"
        >
          <p>{status === "draft" || status === "schedule" ? "Test Your Survey" : "Copy Survey Link"}</p>
        </CustomButton>
        <Tooltip id="survey-copy-button-tooltip" style={{ marginLeft: 10 }} />
      </Flex>
      <CustomIconButton
        onClick={e => {
          e.preventDefault()
          onChangeIsDetailsOpan()
        }}
      >
        {isDetailsOpen ? (
          <ChevronUpIcon width={20} height={20} />
        ) : (
          <ChevronDownIcon width={20} height={20} />
        )}
      </CustomIconButton>
    </Flex>
  )
}
