import React from "react"

import { Flex, Text, Spinner, Button } from "@engaging-tech/components"

const LoadingState = ({ message = "Loading", onCancel }) => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      mt={5}
    >
      <Text mb={6} width="auto" fontWeight={700} fontSize={30}>
        {message}
      </Text>
      <Spinner size={70} color="primary.0" />
      {onCancel && (
        <Button
          width="auto"
          variant="secondary.0"
          px={3}
          mt={6}
          onClick={onCancel}
        >
          Cancel
        </Button>
      )}
    </Flex>
  )
}

export default LoadingState
