import { types } from "./jobs.actions"

export const initialState = {
  isLoading: false,
  jobMatches: null
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_JOB_MATCHES_SUCCESS:
      return { ...state, jobMatches: action.payload, isLoading: false }
    default:
      return state
  }
}
