import Test from "../features/jobs/containers/create/Complete"
import DownForMaintenance from "./DownForMaintenace"
import Content from "./EBN/Content"
import EBNHome from "./EBN/EBN"
import Webinars from "./EBN/Webinars"
import ReportsDashboard from "./ReportsDashboard"
import Welcome from "./Welcome"
import AwardSignUpSuccess from "./account/AwardSignUpSuccess"
import ConfirmEmail from "./account/ConfirmEmail"
import EBNSuccess from "./account/EBNSuccess"
import ForgotPassword from "./account/ForgotPassword"
import JoinBIP from "./account/JoinBIP"
import JoinEvents from "./account/JoinEvents"
import JoinJobs from "./account/JoinJobs"
import JoinLeads from "./account/JoinLeads"
import JoinOurNetwork from "./account/JoinOurNetwork"
import MagicLinkMessage from "./account/MagicLinkMessage"
import MagicLinkRedirect from "./account/MagicLinkRedirect"
import ResetPassword from "./account/ResetPassword"
import SamlAuth from "./account/SamlAccount"
import SendMagicLink from "./account/SendMagicLink"
import SignIn from "./account/SignIn"
import SignUp from "./account/SignUp"
import ThanksForJoining from "./account/ThanksForJoining"
import Thankyou from "./account/Thankyou"
import AwardsHomepage from "./awards/AwardsHomepage"
import EnterAwards from "./awards/EnterAwards"
import EntryForbidden from "./awards/EntryForbidden"
import PaymentSuccess from "./awards/PaymentSuccess"
import Basket from "./basket/Basket"
import InvoicePage from "./basket/InvoicePage"
import SuccessPage from "./basket/SuccessPage"
import BIP from "./business-information"
import EventsHome from "./events/EventsHome"
import PostEvent from "./events/PostEvent"
import JobsCreate from "./jobs/Create"
import LiveJobs from "./jobs/Live"
import JobMatches from "./jobs/Matches"
import Leads from "./leads/Leads"
import Cookies from "./legal/Cookies"
import Privacy from "./legal/Privacy"
import Terms from "./legal/Terms"
import BuyNow from "./logos/BuyNow"
import LogosHome from "./logos/LogosHome"
import ActionPlan from "./reports/ActionPlan"
import Empowerment from "./reports/Empowerment"
import Heatmap from "./reports/Heatmap"
import Highlights from "./reports/Highlights"
import IndicatorBreakdown from "./reports/IndicatorBreakdown"
import InformationSharing from "./reports/InformationSharing"
import InstillingPride from "./reports/InstillingPride"
import JobSatisfaction from "./reports/JobSatisfaction"
import Matrices from "./reports/Matrices"
import Summary from "./reports/Summary"
import SurveyComparison from "./reports/SurveyComparison"
import Wellbeing from "./reports/Wellbeing"
import ExitReportCreation from "./reports/exitReports/ExitReportCreation"
import ExitReports from "./reports/exitReports/ExitReports"
import NewStarterReportCreation from "./reports/starterReports/NewStarterReportCreation"
import StarterReports from "./reports/starterReports/StarterReports"
import LandingPage from "./selfServiceSurveys/LandingPage"
import OrganisationSurvey from "./selfServiceSurveys/OrganisationSurvey"
import WellbeingSurvey from "./selfServiceSurveys/WellbeingSurvey"
import SDPaymentSuccess from "./sliceAndDice/PaymentSuccess"
import {
  DatesAndBranding as DatesAndBrandingReview,
  Participants as ParticipantsReview,
  TitleAndTemplate as TitleAndTemplateReview
} from "./surveyManagement/reviewSurvey"
import SurveyEditMode from "./surveyManagement/survey-edit-mode"
import SurveyViewMode from "./surveyManagement/survey-view-mode"
import { DatesAndBranding, Participants, TitleAndTemplate } from "./surveyManagement/surveyCreate"
import SurveyInformation from "./surveyManagement/surveyInformation/SurveyInformation"
import DraftSurveySelectionDashboard from "./surveyManagement/surveySelection/DraftSurveySelectionDashboard"
import LiveSurveySelectionDashboard from "./surveyManagement/surveySelection/LiveSurveySelectionDashboard"
import SurveyCreateBillingForm from "./surveyManagement/surveys/BillingForm"
import OrderSummary from "./surveyManagement/surveys/OrderSummary"
import SurveyReview from "./surveyManagement/surveys/SurveyReview"
import TemplateSelectionIndex from "./surveyManagement/templateSelection/TemplateSelection"
import TemplateClone from "./surveyManagement/templates/TemplateClone"
import TemplateCreate from "./surveyManagement/templates/TemplateCreate"
import TemplateEdit from "./surveyManagement/templates/TemplateEdit"
import TemplateView from "./surveyManagement/templates/TemplateView"

const EBN = {
  index: EBNHome,
  content: Content,
  webinars: Webinars
}

const jobs = {
  create: JobsCreate,
  live: LiveJobs,
  jobMatches: JobMatches
}

const legal = {
  cookies: Cookies,
  privacy: Privacy,
  terms: Terms
}

const account = {
  signUp: SignUp,
  signIn: SignIn,
  forgotPassword: ForgotPassword,
  resetPassword: ResetPassword,
  confirmEmail: ConfirmEmail,
  joinournetwork: {
    index: JoinOurNetwork,
    leads: JoinLeads,
    events: JoinEvents,
    jobs: JoinJobs,
    BIP: JoinBIP,
    success: EBNSuccess
  },
  joined: ThanksForJoining,
  success: Thankyou,
  awards: AwardSignUpSuccess,
  saml: SamlAuth,
  sendMagicLink: SendMagicLink,
  magicLinkMessage: MagicLinkMessage,
  magicLinkRedirect: MagicLinkRedirect
}

const reports = {
  ReportsDashboard,
  Summary,
  Highlights,
  ActionPlan,
  Matrices,
  IndicatorBreakdown,
  SurveyComparison,
  Heatmap,
  employeeReports: {
    NewStarterReportCreation,
    ExitReportCreation,
    StarterReports,
    ExitReports
  },
  InformationSharing,
  Empowerment,
  Wellbeing,
  InstillingPride,
  JobSatisfaction
}

const surveys = {
  SurveyReview,
  OrderSummary,
  SurveyCreateBillingForm,
  surveySelection: {
    DraftSurveySelectionDashboard,
    LiveSurveySelectionDashboard
  },
  templates: {
    TemplateCreate,
    TemplateView,
    TemplateEdit,
    TemplateClone
  },
  templateSelection: {
    TemplateSelectionIndex
  },
  surveyCreate: {
    TitleAndTemplate,
    DatesAndBranding,
    Participants
  },
  reviewSurvey: {
    TitleAndTemplateReview,
    DatesAndBrandingReview,
    ParticipantsReview
  },
  surveyViewMode: {
    SurveyViewMode,
    SurveyEditMode
  },
  surveyInformation: {
    SurveyInformation
  }
}

const businessInformation = {
  BIP
}

const leads = {
  index: Leads
}
const events = {
  index: EventsHome,
  post: PostEvent
}

const awards = {
  index: AwardsHomepage,
  enterAwards: EnterAwards,
  paymentSuccess: PaymentSuccess,
  entryForbidden: EntryForbidden
}

const logos = {
  index: LogosHome,
  purchase: BuyNow
}

const selfServe = {
  index: LandingPage,
  organisation: OrganisationSurvey,
  wellbeing: WellbeingSurvey
}

const basket = {
  index: Basket,
  invoice: InvoicePage,
  success: SuccessPage
}

const sliceAndDice = {
  success: SDPaymentSuccess
}

export default {
  EBN,
  legal,
  account,
  surveys,
  reports,
  leads,
  events,
  jobs,
  awards,
  Test,
  DownForMaintenance,
  businessInformation,
  Welcome,
  selfServe,
  basket,
  logos,
  sliceAndDice
}
