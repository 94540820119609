import { Button, Text } from "@engaging-tech/components"
import { Link } from "@engaging-tech/routing"
import { getConfig } from "@engaging-tech/ssr-config"
import React from "react"

import ReportCard from "../../../components/ReportCard"

const ActionButtonCard = ({ text, buttonText, link }) => {
  return (
    <ReportCard width={1 / 1} margin="0px 0px 20px 0px">
      <Text textAlign="center" mb={4}>
        {text}
      </Text>
      <Link
        color="primary.0"
        to={`${getConfig().workL.public}/${link}`}
        external
        newTab
      >
        <Button fontSize={[12, 3]} minWidth={[175, 200]} variant="secondary.0">
          {buttonText}
        </Button>
      </Link>
    </ReportCard>
  )
}

export default ActionButtonCard
