import { adjustDate } from "../../../../helpers/FormatDate"
import { types } from "./survey-edit-mode.actions"

const workLLogo = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/WorkL_Logo_TEAL.png`)

export const initialState = {
  surveyId: "",
  isLoading: false,
  success: false,
  isSaving: false,
  hasError: false,
  surveyForm: {
    id: "",
    title: "",
    introduction: [],
    template: {},
    status: "",
    meta: {
      private: false,
      startDate: new Date(adjustDate(new Date(), 1, 0, 0).setHours(5, 0, 0, 0)), // start script runs at 8am Monday-Friday
      endDate: new Date(adjustDate(new Date(), 2, 0, 0).setHours(4, 0, 0, 0)), // closing script runs at 1.35am every day
      brand: {
        logo: workLLogo,
        colour: "#013e4c"
      },
      mode: {
        mode: "uniqueSubmission",
        amount: 5,
        recipients: []
      },
      creditsPaid: 0,
      frequencySettings: {
        frequency: "never",
        endDate: null
      },
      resultsEmails: []
    }
  }
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPLOAD_LOGO:
      return {
        ...state,
        isSaving: true
      }

    case types.UPDATE_SURVEY: {
      return {
        ...state,
        isSaving: true,
        success: false,
        survey: { ...state.survey, ...action.payload }
      }
    }

    case types.UPLOAD_LOGO_SUCCESS:
      return {
        ...state,
        isSaving: false,
        survey: {
          ...state.survey,
          meta: {
            ...state.survey.meta,
            brand: {
              ...state.survey.meta.brand,
              logo: action.payload
            }
          }
        },
        hasError: false
      }

    case types.UPDATE_SURVEY_SUCCESS:
      return {
        ...state,
        isSaving: false,
        surveyId: action.payload,
        success: true,
        hasError: false
      }

    case types.UPDATE_SURVEY_FAIL:
    case types.UPLOAD_LOGO_FAIL:
      return { ...state, isSaving: false, isLoading: false, success: false, hasError: true }

    default:
      return state
  }
}
