import { Flex, Icon, Text } from "@engaging-tech/components"
import { titleCaps } from "@engaging-tech/frontend-utils"
import React from "react"

const determineProps = status => {
  if (status === "completed") return { name: "check", color: "textSecondary.0", fontWeight: 400 }
  if (status === "live") return { name: "alarm", color: "dark.1", fontWeight: 500 }
  if (status === "scheduled") return { name: "event", color: "dark.2", fontWeight: 400 }

  return {
    name: "research",
    color: "dark.2",
    fontWeight: 400
  }
}

export default function SurveyStatus({ status }) {
  const statusProps = determineProps(status)

  return (
    <Flex
      alignItems="center"
      width={["100%", "100%", "fit-content"]}
      minWidth={["100%", "100%", "fit-content"]}
      justifyContent="flex-start"
      mb={[3, 3, 0]}
    >
      <Icon {...statusProps} mr="10px" />
      <Text fontSize={3} {...statusProps} width="auto">
        {titleCaps(status)}
      </Text>
    </Flex>
  )
}
