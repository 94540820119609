import React from "react"
import ReportLayout from "../../layouts/ReportLayout"
import ReportWrapper from "../../features/Reports/views/ReportWrapper"
import ActionPlanView from "../../features/Reports/views/ActionPlan"
import { OrgGuard } from "../../features/account"

const ActionPlan = () => {
  return (
    <OrgGuard>
      <ReportLayout>
        <ReportWrapper>
          <ActionPlanView />
        </ReportWrapper>
      </ReportLayout>
    </OrgGuard>
  )
}

export default ActionPlan
