import { useEffect } from "react"
import { connect } from "react-redux"
import { ReportActions } from ".."
import { getSurveyType } from "../store/report.selectors"

const ReportDashboardLoaderComponent = ({ onLoad, surveyType }) => {
  const params =
    surveyType.includes("awards") || surveyType.includes("organisation")
      ? { surveyType, isRoot: true }
      : { surveyType }
  useEffect(() => {
    onLoad(params)
  }, [onLoad, surveyType])

  return null
}

const mapState = state => ({
  surveyType: getSurveyType(state)
})

const mapDispatch = dispatch => ({
  onLoad: reportSearchFilters =>
    dispatch(ReportActions.loadReports(reportSearchFilters))
})

const ReportDashboardLoader = connect(
  mapState,
  mapDispatch
)(ReportDashboardLoaderComponent)

export default ReportDashboardLoader
