import { DesktopOnly, Flex, Text } from "@engaging-tech/components"
import { Navbar } from "@engaging-tech/navigation"
import { Link, usePaths, useRoutes } from "@engaging-tech/routing"
import { getConfig } from "@engaging-tech/ssr-config"
import { ThirdPartyActions } from "@engaging-tech/third-party-cookies"
import { Auth } from "aws-amplify"
import React from "react"
import { useDispatch } from "react-redux"
import styled from "styled-components"

import { GET_BASKET } from "../../../graphql/queries"
import useBusinessAppQuery from "../../../hooks/useBusinessAppQuery"
import { getBasketFail, getBasketReq, getBasketSuccess } from "../../../store/basket/basket.actions"
import { signOutSuccess } from "../../account/store/account.actions"
import SiteLogo from "./SiteLogo"

const profilePlaceholder = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/No_Photo_Empty_State.svg`)
const workLLogo = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/WorkL_Logo_W_TEAL.png`)

const StyledImg = styled.img`
  width: 20px;
  height: 15px;
  margin-right: ${({ theme }) => theme.space[2]}px;
`

const ExtraNavComponent = () => {
  return (
    <Link to={getConfig().publicSite} external newTab>
      <Flex width="auto" alignItems="center" flexDirection="row" ml={3} mt={[3, 3, 0]}>
        <DesktopOnly>
          <Flex width={8} height={8} bg="rgba(0, 0, 0, 0.16)" borderRadius={99} mr={3} />
        </DesktopOnly>
        <Flex alignItems="center" bg="secondary.1" borderRadius={99} height={34} py={1} px={2}>
          <Text color="dark.2" fontSize={3} mx={2}>
            For Individuals
          </Text>
          <StyledImg src={workLLogo} />
        </Flex>
      </Flex>
    </Link>
  )
}

const Nav = ({
  isAuthenticated,
  isEBNMember,
  organisationDetails,
  hasBIPSubscription,
  headerLogo,
  basketLength
}) => {
  const routes = useRoutes()
  const paths = usePaths()

  const navRoutes = routes.filter(route =>
    [paths.home.index, paths.surveys.index, paths.reports.index].includes(route.path)
  )

  const allAccountRoutes = routes.filter(route => route.path.includes(paths.account.index))[0].children

  const dispatch = useDispatch()

  const onSignOut = async () => {
    await Auth.signOut()
    dispatch(ThirdPartyActions.setClearConsent())
    dispatch(signOutSuccess())
    window.localStorage.removeItem("surveyIdUpgrade")
  }

  const signedInRoutes = [
    {
      linkProps: {
        onClick: onSignOut
      },
      description: "Sign Out",
      icon: "meeting_room"
    }
  ]

  const signedOutRoutes = allAccountRoutes.filter(
    route => route.path === paths.account.signIn || route.path === paths.account.signUp
  )

  useBusinessAppQuery(GET_BASKET, {
    initAction: getBasketReq,
    successAction: getBasketSuccess,
    failAction: getBasketFail
  })

  const basketData = {
    items: basketLength,
    route: paths.yourBasket.index
  }

  return (
    <Navbar
      siteLogo={SiteLogo}
      routes={navRoutes}
      accountRoutes={isAuthenticated ? signedInRoutes : signedOutRoutes}
      headerLogo={headerLogo}
      accountRoutesProps={{
        bg: "secondary.1",
        color: "dark.2",
        textStyle: {
          color: "dark.2"
        }
      }}
      zIndex={30000}
      tabBarColor="primary.1"
      userDetails={{
        isAuthenticated,
        details: organisationDetails ? { ...organisationDetails.currentUser, premium: isEBNMember } : null,
        nonPremiumColor: "dark.4",
        premiumColor: "primary.1"
      }}
      navbarItemColor={{ selected: "primary.0", unSelected: "dark.2" }}
      tabBarItemColor={{ selected: "dark.0", unSelected: "dark.2" }}
      elevation={3}
      height={120}
      mobileNavbarHeight={60}
      holdingImage={profilePlaceholder}
      profilePath={
        hasBIPSubscription || isEBNMember?.active
          ? paths.home?.businessInformationHub?.index
          : paths.account.joinBIP
      }
      ExtraNavComponent={ExtraNavComponent}
      linkTo={paths.welcome}
      cartData={basketData}
    />
  )
}

export default Nav
