import React from "react"
import styled from "styled-components"
import {
  Text,
  Box,
  UserDetails,
  Spinner,
  Flex
} from "@engaging-tech/components"

import Post from "../Post"
import Thumbnail from "../Post/Thumbnail"
import File from "../Post/File"

const profilePlaceholder = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/No_Photo_Empty_State.svg`)

const extractFileName = url => {
  const splitUrl = url.split("/")
  return splitUrl[splitUrl.length - 1]
}

const ResearchCard = ({ research }) => {
  if (!research) {
    return <Spinner color="primary.0" />
  }
  if (research)
    return (
      <Flex width={1 / 1} justifyContent="center">
        <Post.Card bg="light.0" mb={3}>
          <Post.Header bg="secondary.1" mb={3} title="research" />
          <Post.Content>
            {research.user && (
              <UserDetails
                userHoldingImage={profilePlaceholder}
                user={research.user}
                time={research.publishedAt}
              />
            )}
            <Flex mb={4} flexDirection="column">
              <Box mb={3}>
                <Text width="auto" fontSize={4} fontWeight="500">
                  {research.title}
                </Text>
              </Box>
              <Flex flexDirection={["column", "column", "row"]}>
                {research.coverImage && (
                  <Thumbnail
                    minHeight={["186px", "186px", "200px"]}
                    width={[1, 1 / 2, "186px"]}
                    mb={[3, 3, 0]}
                    mr={[0, 0, 3]}
                    src={research.coverImage.source}
                  />
                )}
                <Box>
                  <Box mb={1}>
                    {research.series && (
                      <Text fontSize={4} fontWeight="500">
                        {research.series.title}
                      </Text>
                    )}
                  </Box>
                  <Box>
                    <Text color="dark.2" lineHeight="1.6" fontSize={3}>
                      {research.summary}
                    </Text>
                  </Box>
                </Box>
              </Flex>
            </Flex>

            <Flex mb={3} justifyContent="center">
              {research.content.length > 0 &&
                research.content.map(document => (
                  <File
                    key={document.source}
                    name={extractFileName(document.source)}
                    url={document.source}
                  />
                ))}
            </Flex>
          </Post.Content>
        </Post.Card>
      </Flex>
    )
}

export default ResearchCard
