import React from "react"
import { AllRoutes } from "@engaging-tech/routing"
import { connect, useSelector } from "react-redux"
import { Flex, UserInterfaceNotifications } from "@engaging-tech/components"
import {
  PageviewTracker,
  ConsentBanner
} from "@engaging-tech/third-party-cookies"
import { Amplify } from "aws-amplify"
import routes from "./routes"
import Bootstrap from "./features/ui/containers/Bootstrap"
import UiLoader from "./features/ui/containers/UiLoader"
import awsconfig from "./aws-exports"
import { getFeebackDialogIsOpen } from "./features/ui/store/ui.selectors"
import FeedbackDialog from "./features/ui/components/feedbackDialog"
import useFreschatWidget from "./hooks/useFreshchatWidget"

Amplify.configure(awsconfig)

const cookieIconImg = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/cookie.png`)

const App = () => {
  const feedbackDialogIsOpen = useSelector(getFeebackDialogIsOpen)
  useFreschatWidget()
  return (
    <Flex
      id="app-wrapper"
      flexDirection="column"
      alignItems="center"
      width="100%"
      bg="light.0"
    >
      <UiLoader />
      <Bootstrap />
      <AllRoutes routes={routes} />
      {feedbackDialogIsOpen ? <FeedbackDialog /> : null}
      <UserInterfaceNotifications connect={connect} />
      {typeof window !== "undefined" && (
        <ConsentBanner iconImage={cookieIconImg} exceptions={["in-app-view"]} />
      )}
      <PageviewTracker />
    </Flex>
  )
}

export default App
