import React from "react";
import { Flex } from "@engaging-tech/components";
import ReportElementsLayout from "./ReportElementsLayout"

export const ReportLayout = ({ components, step, isSixStepScores, tabs }) => (
  <Flex
    justifyContent="space-between"
    flexDirection={["column", "column", "row"]}
    flexWrap="wrap"
    alignItems="stretch"
    {...(isSixStepScores && {
      border: '0.5px solid #173C5A',
      padding: ["12px 28px 12px 12px", "30px 57px"],
      borderRadius: "0 5px 5px 5px"
    })}
  >
    <ReportElementsLayout componentTags={components} step={step} tabs={tabs} />
  </Flex>
);
