import React from "react"
import { Flex, Text } from "@engaging-tech/components"

const underConstructionImg = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/under_construction.svg`)

const DownForMaintenance = () => (
  <Flex flexDirection="column" p={[3, 5, 6]}>
    <Text fontSize={7} color="dark.1" fontWeight={700}>
      We’re fine-tuning a few things...
    </Text>
    <Flex
      my={[5, 6]}
      justifyContent="center"
      alignItems="center"
      width="100%"
      height={[190, 230, 280]}
    >
      <img
        alt="Down for maintenance"
        src={underConstructionImg}
        style={{ height: "100%" }}
      />
    </Flex>

    <Text fontSize={[4, 5]} color="dark.1" fontWeight={700} mb={3}>
      Due to planned improvements, WorkL for Business is currently unavailable,
      however it will be back in a day, better than ever.
    </Text>

    <Text fontSize={[4, 5]} color="dark.1" fontWeight={700}>
      Check back tomorrow!
    </Text>
  </Flex>
)

export default DownForMaintenance
