import { Flex, MotionFlex , CustomHooks} from "@engaging-tech/components"

import { AnimatePresence } from "framer-motion"

import styled from "styled-components"

import React from "react"

const stepOneImageLight = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/surveyManagement/surveyInformation/survey_information_step_one_light.svg`)
const stepTwoImageLight = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/surveyManagement/surveyInformation/survey_information_step_two_light.svg`)
const stepThreeImageLight = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/surveyManagement/surveyInformation/survey_information_step_three_light.svg`)
const stepOneImageDark = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/surveyManagement/surveyInformation/survey_information_step_one_dark.svg`)
const stepTwoImageDark = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/surveyManagement/surveyInformation/survey_information_step_two_dark.svg`)
const stepThreeImageDark = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/surveyManagement/surveyInformation/survey_information_step_three_dark.svg`)

const Image = styled.img`
  height: 60%;
  width: 60%;
  object-fit: contain;
`

const StepCircle = ({ step, stepHandler, selectedStep }) => {
  const device = CustomHooks.useClientDevice()
  const size = device === "MOBILE" ? 34 : 36
  const ring = device === "MOBILE" ? 16 : 18
  return (
    <Flex
      minHeight={size + ring}
      minWidth={size + ring}
      height={size + ring}
      width={size + ring}
      position="relative"
    >
      <MotionFlex
        position="absolute"
        minHeight={size + ring}
        minWidth={size + ring}
        height={size + ring}
        width={size + ring}
        border={`${size / 10}px solid #193c5a`}
        borderRadius={(size + ring) / 2}
        variants={{
          unActive: {
            scale: 0.01,
            transition: {}
          },
          active: {
            scale: 1,
            transition: { delay: 0.3 }
          }
        }}
        initial="unActive"
        animate={step === selectedStep ? "active" : "unActive"}
      />
      <MotionFlex
        justifyContent="center"
        alignItems="center"
        position="absolute"
        height={size}
        width={size}
        style={{ top: ring / 2, left: ring / 2, cursor: "pointer" }}
        onTap={() => stepHandler(step)}
        minHeight={size}
        minWidth={size}
        borderRadius={size / 2}
        animate={
          selectedStep >= step
            ? { backgroundColor: "#173C5A" }
            : { backgroundColor: "#CDEAFF" }
        }
        transition={{ duration: 0.4 }}
      >
        <Image
          src={(() => {
            if (selectedStep >= step) {
              switch (step) {
                case 1:
                  return stepOneImageLight
                case 2:
                  return stepTwoImageLight
                case 3:
                  return stepThreeImageLight
                default:
                  return stepOneImageLight
              }
            }
            switch (step) {
              case 1:
                return stepOneImageDark
              case 2:
                return stepTwoImageDark
              case 3:
                return stepThreeImageDark
              default:
                return stepOneImageDark
            }
          })()}
        />
      </MotionFlex>
    </Flex>
  )
}

const MiddleLine = ({ step, selectedStep }) => {
  return (
    <Flex
      width="100%"
      bg="secondary.1"
      height={[10, 12, 10]}
      mx={[2, 2]}
      borderRadius={8}
    >
      <AnimatePresence>
        {selectedStep > step && (
          <MotionFlex
            height="100%"
            initial={{ width: "0%", opacity: 0 }}
            bg="secondary.0"
            animate={{
              width: "100%",
              opacity: 1
            }}
            exit={{
              width: "0%",
              opacity: 0,
              transition: { opacity: { delay: 0.4 } }
            }}
            transition={{
              type: "tween",
              ease: "easeInOut",
              duration: 0.4,
              opacity: { duration: 0.1 }
            }}
            borderRadius={8}
          />
        )}
      </AnimatePresence>
    </Flex>
  )
}

const StepSlide = ({ selectedStep, stepHandler, ...props }) => {
  return (
    <Flex justifyContent="space-between" alignItems="center" {...props}>
      <StepCircle
        selectedStep={selectedStep}
        step={1}
        stepHandler={stepHandler}
      />
      <MiddleLine step={1} selectedStep={selectedStep} />
      <StepCircle
        selectedStep={selectedStep}
        step={2}
        stepHandler={stepHandler}
      />
      <MiddleLine step={2} selectedStep={selectedStep} />
      <StepCircle
        selectedStep={selectedStep}
        step={3}
        stepHandler={stepHandler}
      />
    </Flex>
  )
}

export default StepSlide
