/* eslint-disable react-hooks/exhaustive-deps */
import { Flex } from "@engaging-tech/components"
import { usePaths } from "@engaging-tech/routing"
import React, { useEffect } from "react"

import addParams from "../../../../utils/addParams"
import SliceAndDiceUpsell from "../../../sliceAndDice/components/SliceAndDiceUpsell"
import ListSurveys from "../../../ui/components/ListSurveys"
import DashboardTopBanner from "../../components/DashboardTopBanner"
import CreditsDisplay from "../containers/components/CreditsDisplay"

const SurveySelectionDashboard = ({
  draft,
  draftSurveys,
  scheduledSurveys,
  liveSurveys,
  onLoad,
  isLoading,
  surveysError,
  hasSliceAndDiceLicence,
  hasAnAwardSurvey
}) => {
  useEffect(() => {
    onLoad()
  }, [onLoad])
  const paths = usePaths()

  useEffect(() => {
    addParams(hasSliceAndDiceLicence, "slice-and-dice", "inactive")
  }, [])

  const surveysToShow = draft
    ? draftSurveys
    : [...liveSurveys, ...scheduledSurveys]

  return (
    <Flex flexDirection="column" width="100%" maxWidth="100%">
      <DashboardTopBanner
        title={draft ? "Draft Surveys Dashboard" : "Live Surveys Dashboard"}
        subtitle={
          draft
            ? "View your draft surveys"
            : "View your live & scheduled surveys"
        }
        ExtraButton={CreditsDisplay}
        buttonPath={
          surveysToShow.length !== 0 ? paths.surveys.surveyCreation.index : null
        }
        buttonText="Create Survey"
      />
      <ListSurveys
        isLoading={isLoading}
        surveysError={surveysError}
        surveysToShow={surveysToShow}
      />
      {hasAnAwardSurvey &&
        !hasSliceAndDiceLicence &&
        surveysToShow.length > 0 &&
        !draft && <SliceAndDiceUpsell chooseCompanySize />}
    </Flex>
  )
}

export default SurveySelectionDashboard
