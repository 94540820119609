import React from "react"
import { Flex, Box } from "@engaging-tech/components"
import Paragraph from "@engaging-tech/components/dist/lib/Paragraph/Paragraph"

const EmptyDashboard = reportStatus => {
  return (
    <Flex width={1 / 1} justifyContent="flex-start" flexDirection="column">
      {reportStatus === false ? (
        <Flex flexDirection="column" mb={4}>
          <Paragraph color="dark.2" mb={1} fontSize={["12px", "14px"]}>
            You currently have no reports.
          </Paragraph>
        </Flex>
      ) : (
        <Flex flexDirection="column" mb={4}>
          <Paragraph color="dark.2" mb={1} fontSize={["12px", "14px"]}>
            Sorry, the report you have searched for does not exist.
          </Paragraph>
        </Flex>
      )}

      <Flex flexDirection="column" justifyContent="center" alignItems="center">
        <Box width={["200px", "500px"]} mb={4}>
          <img
            width="100%"
            // eslint-disable-next-line global-require
            src={require(`${process.env.RAZZLE_PUBLIC_DIR}/img/EmptySurveyImage.png`)}
            alt="no surveys"
            position="relative"
          />
        </Box>
      </Flex>
    </Flex>
  )
}
export default EmptyDashboard
