import React from "react"
import { OrgGuard } from "../../features/account"
import DetailsLayout from "../../layouts/DetailsLayout"
import SeeAllWebinarsView from "../../features/EBN/views/SeeAllWebinars"

const Webinars = () => {
  return (
    <OrgGuard>
      <DetailsLayout bg="#EEEEEE" px={3}>
        <SeeAllWebinarsView />
      </DetailsLayout>
    </OrgGuard>
  )
}
export default Webinars
