import React from "react"
import styled from "styled-components"
import {
  Text,
  Paragraph,
  Box,
  UserDetails,
  Spinner,
  Flex
} from "@engaging-tech/components"

import Post from "../Post"

const profilePlaceholder = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/No_Photo_Empty_State.svg`)

const CoverImage = styled.img`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.space[3]}px;
`

const ContentSection = ({ body, ...props }) => (
  <Paragraph mb={3} {...props}>
    {body}
  </Paragraph>
)

const ArticleCard = ({ article }) => {
  if (!article) {
    return <Spinner color="primary.0" />
  }
  if (article)
    return (
      <Flex width={1 / 1} justifyContent="center">
        <Post.Card bg="light.0">
          <Post.Header bg="secondary.1" mb={3} title="article" />
          <Post.Content>
            {article.user && (
              <UserDetails
                userHoldingImage={profilePlaceholder}
                user={article.user}
                time={article.publishedAt}
              />
            )}
            {article.coverImage.source && (
              <CoverImage
                alt={article.coverImage.caption}
                src={article.coverImage.source}
              />
            )}
            <Box mb={2}>
              <Text fontWeight="500">{article.title}</Text>
            </Box>
            {article.content.map((content, index) => (
              <ContentSection
                color="dark.2"
                body={content.body}
                /* eslint-disable-next-line react/no-array-index-key */
                key={index}
              />
            ))}
          </Post.Content>
        </Post.Card>
      </Flex>
    )
}

export default ArticleCard
