const awsmobile = {
  aws_project_region: 'eu-west-2',
  aws_cognito_region: 'eu-west-2',
  aws_user_pools_id: 'eu-west-2_kHBOjuoGy',
  aws_user_pools_web_client_id: '3029q4mtidjvvsn4cs8sgpp479',
  oauth: {},
  aws_appsync_graphqlEndpoint: 'https://',
  aws_appsync_region: 'eu-west-2',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS'
};

export default awsmobile;
