import { Flex, Text } from "@engaging-tech/components"
import React from "react"

const SubQuestion = ({ subQuestionObj: { id, subQuestion } }) => (
  <Text key={id} mb="1px">{`${id}) ${subQuestion}`}</Text>
)

const Question = ({ question: { mainQuestion, id, subQuestions } }) => (
  <Flex flexWrap="wrap" mb={3}>
    <Text fontWeight={600} mb={1}>{`${id}. ${mainQuestion}`}</Text>
    <Flex flexWrap="wrap" ml={4}>
      {subQuestions?.map(subQuestionObj => (
        <SubQuestion subQuestionObj={subQuestionObj} />
      ))}
    </Flex>
  </Flex>
)

const ResolveQuestions = ({ resolveQuestions }) => (
  <>
    {resolveQuestions?.map(question => (
      <Question question={question} />
    ))}
  </>
)

export default ResolveQuestions
