import { Flex, H4, Paragraph, Text } from "@engaging-tech/components"
import React from "react"
import { getLabelData } from "../../../components/ScoreLabel"

const QuestionIntro = ({ title, score }) => (
  <Flex flexWrap="wrap">
    <H4 fontSize={5}>{title}</H4>
    <Paragraph mb={4}>
      Your score: <Text color={getLabelData(score).color}>{score}%</Text>
    </Paragraph>
    <Text mb={3}>
      If you score poorly on this question there could be a number of reasons.
      You should therefore ask yourself the following:
    </Text>
  </Flex>
)

export default QuestionIntro
