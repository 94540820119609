/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react"
import { Flex, Text } from "@engaging-tech/components"
import { object, string } from "yup"
import { useFormik } from "formik"
import useFreeze from "../../lib/useFreeze"
import EditBodyFlex from "../../sharedStyling/EditBodyFlex"
import TemplateQuestionContext from "../TemplateQuestionContext"
import StyledTextField from "../../sharedStyling/StyledTextField"
import DeleteButton from "./DeleteButton"
import AbsoluteErrorText from "../../sharedStyling/AbsoluteErrorText"

const Input = ({ shortText, onChange, i, deleteOption, setPageErrors }) => {
  const { isCore, id } = useContext(TemplateQuestionContext)

  const formik = useFormik({
    initialValues: {
      shortText: useFreeze(shortText)
    },
    validationSchema: object({
      shortText: string().required("Cannot be empty")
    }),
    validateOnMount: true
  })

  useEffect(() => {
    const handler = setTimeout(() => {
      if (!isCore) onChange(formik.values.shortText, i, id)
      if (!isCore) setPageErrors(formik.errors?.shortText, "shortText", id)
    }, 500)

    return () => clearTimeout(handler)
  }, [formik.values.shortText, formik.errors])

  return (
    <Flex
      key={i}
      alignItems="flex-end"
      pr="10px"
      mb="6px"
      my={2}
      position="relative"
    >
      <Flex
        borderRadius="100%"
        bg="dark.4"
        width="30px"
        height="30px"
        alignItems="center"
        mr={2}
      >
        <Text width="30px" textAlign="center">
          {i + 1}
        </Text>
      </Flex>
      <StyledTextField
        type="text"
        height="100%"
        value={formik.values.shortText}
        onChange={formik.handleChange}
        placeholder="Short answer label (optional)"
        width="97%"
        id="shortText"
        noLabel
        disabled={isCore}
        focusedBackgroundColor="light.0"
        borderColor="#b5b5b5"
        padding="2px 5px"
        bg="light.0"
      />
      <AbsoluteErrorText right="47px">
        {formik.errors?.shortText}
      </AbsoluteErrorText>
      {!isCore && <DeleteButton onClick={() => deleteOption(i, id)} />}
    </Flex>
  )
}

const ShortTextQuestionEdit = ({ deleteOption, onChange, setPageErrors }) => {
  const { fields } = useContext(TemplateQuestionContext)

  return (
    <EditBodyFlex>
      {fields?.map((field, i) => (
        <Input
          key={i}
          shortText={field.label}
          onChange={onChange}
          i={i}
          deleteOption={deleteOption}
          setPageErrors={setPageErrors}
        />
      ))}
    </EditBodyFlex>
  )
}

export default ShortTextQuestionEdit
