import { Box, CustomHooks } from "@engaging-tech/components"
import React from "react"
import { useSelector } from "react-redux"
import { useParams } from "react-router"
import styled from "styled-components"

import SliceAndDiceReportWrapper from "../../../sliceAndDice/components/SliceAndDiceReportWrapper"
import ReportCard from "../../components/ReportCard"
import {
  getInstantActionPlan,
  getSliceAndDiceReport
} from "../../store/report.selectors"
import AreaForComponent from "./AreaForComponent"

const Grid = styled(Box)`
  display: grid;
  grid-template-columns: ${({ device }) =>
    device !== "MOBILE" ? "50% 50%" : "100%"};
  justify-items: center;
`

const InstantActionPlanning = () => {
  const { low, high } = useSelector(getInstantActionPlan)
  const { id } = useParams()
  const device = CustomHooks.useClientDevice()
  const isSliceAndDiceMember = useSelector(getSliceAndDiceReport)?.active
  const heading = "Instant Action Planning"
  const upgradedReport = new URLSearchParams(window.location.search).get(
    "upgraded"
  )

  return (
    <ReportCard
      heading={heading}
      width="100%"
      showUpgradeLabel={upgradedReport}
    >
      <SliceAndDiceReportWrapper
        isSAndDMember={isSliceAndDiceMember}
        feature="instantActionPlanning"
      >
        <Grid justifyContent="space-between" width={1 / 1} device={device}>
          <AreaForComponent
            name={low.name}
            questions={low.questionGroup.questions}
            title={low.questionGroup.title}
            score={low.score}
            type="low"
            reportId={id}
            device={device}
          />
          <AreaForComponent
            name={high.name}
            questions={high.questionGroup.questions}
            title={high.questionGroup.title}
            score={high.score}
            type="high"
            reportId={id}
            device={device}
          />
        </Grid>
      </SliceAndDiceReportWrapper>
    </ReportCard>
  )
}

export default InstantActionPlanning
