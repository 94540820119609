import React from "react"
import {
  Text,
  Box,
  UserDetails,
  Flex,
  Spinner
} from "@engaging-tech/components"

import Post from "../Post"

const profilePlaceholder = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/No_Photo_Empty_State.svg`)

const LectureCard = ({ lecture }) => {
  if (!lecture) {
    return <Spinner color="primary.0" />
  }
  if (lecture)
    return (
      <Flex width={1 / 1} justifyContent="center">
        <Post.Card bg="light.0">
          <Post.Header bg="secondary.1" mb={3} title="lecture" />
          <Post.Content>
            {lecture.user && (
              <UserDetails
                userHoldingImage={profilePlaceholder}
                user={lecture.user}
                time={lecture.publishedAt}
              />
            )}
            <Box>
              <Box mb={1}>
                <Text fontWeight="500">{lecture.title}</Text>
              </Box>
              <Box mb={2}>
                {lecture.series && (
                  <Text color="dark.2" fontSize={3}>
                    {lecture.series.title}
                  </Text>
                )}
              </Box>
              <Box>
                <Text color="dark.2" lineHeight="1.4">
                  {lecture.summary}
                </Text>
              </Box>
            </Box>
            {lecture.content.map(video => (
              <Post.Video
                mb={2}
                url={video.source}
                thumbnail={lecture.coverImage.source}
                key={video.source}
              />
            ))}
          </Post.Content>
        </Post.Card>
      </Flex>
    )
}

export default LectureCard
