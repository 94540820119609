import React from "react"
import {
  Text,
  Flex,
  Spinner,
  TabletUp,
  MobileOnly
} from "@engaging-tech/components"
import { Link } from "@engaging-tech/routing"

import styled from "styled-components"
import Carousel from "../../ui/components/Carousel"

const Image = styled.img`
  border-radius: 4px;
  height: 112px;
  width: 200px;
  object-fit: cover;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints[0]}px) {
    height: 100px;
    width: 165px;
  }
`

const emptyStateImage = `${process.env.RAZZLE_PUBLIC_DIR}/img/Empty_state_image.svg`

const Article = ({ coverImage, id, title }) => (
  <Link to={`network/articles/${id}`}>
    <Flex
      mr={[0, 2, 3]}
      mb={[2, 0, 0]}
      flexDirection="column"
      width={["165px", "200px", "200px"]}
    >
      <Image
        src={coverImage?.source !== "" ? coverImage.source : emptyStateImage}
        alt="Article"
      />
      <Text my={1} fontSize={3} fontWeight={700}>
        {title.slice(0, 70)}
        {title.length > 70 && <Text>...</Text>}
      </Text>
    </Flex>
  </Link>
)

const Articles = ({ articles }) => {
  if (!articles) {
    return <Spinner color="primary.0" />
  }
  return (
    <>
      <TabletUp width={1 / 1}>
        <Carousel
          carouselItems={articles}
          numberOfItems={3}
          componentToShow={Article}
        />
      </TabletUp>
      <MobileOnly width={1 / 1}>
        <Carousel
          carouselItems={articles}
          numberOfItems={1}
          componentToShow={Article}
        />
      </MobileOnly>
    </>
  )
}

export default Articles
