/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react"
import { Flex, Text } from "@engaging-tech/components"
import styled from "styled-components"
import { useFormik } from "formik"
import { string, object } from "yup"
import TemplateQuestionContext from "../TemplateQuestionContext"
import EditBodyFlex from "../../sharedStyling/EditBodyFlex"
import StyledTextField from "../../sharedStyling/StyledTextField"
import DeleteButton from "./DeleteButton"
import useFreeze from "../../lib/useFreeze"
import AbsoluteErrorText from "../../sharedStyling/AbsoluteErrorText"

const BorderedFlex = styled(Flex)`
  align-items: center;
  height: inherit;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-top: ${({ idx, theme }) =>
    idx === 0 ? `1px solid ${theme.colors.dark[3]}` : "none"};
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.dark[3]}`};
  border-top-right-radius: ${({ idx }) => (idx === 0 ? "6px" : "0px")};
  border-top-left-radius: ${({ idx }) => (idx === 0 ? "6px" : "0px")};
  border-bottom-left-radius: ${({ lastIdx }) => (lastIdx ? "6px" : "0px")};
  border-bottom-right-radius: ${({ lastIdx }) => (lastIdx ? "6px" : "0px")};
  background-color${({ theme }) => theme.colors.light[1]};
  position: relative;
  width: 100%;
`

const Input = ({ entry, i, deleteOption, onChange, setPageErrors }) => {
  const { values, isCore, id } = useContext(TemplateQuestionContext)

  const formik = useFormik({
    initialValues: useFreeze({
      dropdown: entry.value
    }),
    validationSchema: () =>
      object().shape({
        dropdown: string().required("Cannot be empty")
      }),
    validateOnMount: true
  })

  useEffect(() => {
    const handler = setTimeout(() => {
      if (!isCore) onChange(formik.values.dropdown, i, id)
      if (!isCore) setPageErrors(formik.errors?.dropdown, "dropdown", id)
    }, 500)

    return () => clearTimeout(handler)
  }, [formik.values.dropdown, formik.errors])

  return (
    <Flex alignItems="center" height="40px" key={entry.id}>
      <Text width="30px" textAlign="center">
        {i + 1}
      </Text>
      <BorderedFlex idx={i} lastIdx={values?.length === i + 1} mr="10px">
        <StyledTextField
          type="text"
          height="100%"
          value={formik.values.dropdown}
          id="dropdown"
          onChange={formik.handleChange}
          placeholder="Option label"
          width="100%"
          noLabel
          noHighlight
          disabled={isCore}
          backgroundColor="transparent"
          bg="rgba(0,0,0,0)"
          focusedBackgroundColor="rgba(0,0,0,0)"
          borderColor="#b5b5b5"
          padding="2px 5px"
        />
        <AbsoluteErrorText>{formik.errors?.dropdown}</AbsoluteErrorText>
      </BorderedFlex>
      {!isCore && <DeleteButton onClick={() => deleteOption(i, id)} />}
    </Flex>
  )
}

const DropdownQuestionEdit = ({ deleteOption, onChange, setPageErrors }) => {
  const { values } = useContext(TemplateQuestionContext)

  const renderValue = values?.map((entry, i) => (
    <Input
      key={entry.id}
      entry={entry}
      i={i}
      onChange={onChange}
      deleteOption={deleteOption}
      setPageErrors={setPageErrors}
    />
  ))

  return <EditBodyFlex>{renderValue}</EditBodyFlex>
}

export default DropdownQuestionEdit
