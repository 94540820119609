import { heatMapTabsValues } from "../Heatmaps/components/HeatMapTabs/heatMapTabsValues"
import { types } from "./report.actions"

const areObjectsEqual = (obj1, obj2) => {
  return JSON.stringify(obj1) === JSON.stringify(obj2)
}

const findMatchingObject = (array, obj) => {
  return array.find(item =>
    item.heatmapFilter.some(filter => areObjectsEqual(filter, obj))
  )
}

const findMatchingValueObj = (array, obj) => {
  return array.find(item =>
    item.heatmapFilter.some(filter =>
      filter.values.some(value => areObjectsEqual(value, obj))
    )
  )
}

const sorterNumberArray = (a, b) => {
  return a - b
}

/* 
  Sometimes when you remove filters, you might end up with rows that have exactly the same data.
  This function removes any duplicates.
  Example:
  Select filters: Male, Female, White
  Combine: Male + Female, White + Male + Female.
  Remove filter: White.
  You are left with Male + Female and Male + Female combination
  This function will remove the duplicate
*/
const removeDuplicates = arr => {
  const uniqueHeatmaps = new Set()
  const result = []

  arr.forEach(item => {
    const sortedHeatmapFilter = item.heatmapFilter
      .map(filter => ({
        ...filter,
        values: filter.values.sort((a, b) => a.valueId.localeCompare(b.valueId))
      }))
      .sort((a, b) => a.questionId.localeCompare(b.questionId))

    const heatmapString = JSON.stringify(sortedHeatmapFilter)

    if (!uniqueHeatmaps.has(heatmapString)) {
      uniqueHeatmaps.add(heatmapString)
      result.push(item)
    }
  })

  return result
}

export const initialState = {
  surveyComparison: {
    stage: 0,
    breadcrumbs: [],
    checkedList: [],
    subCheckedList: [],
    data: [],
    dataset: [],
    startDate: null,
    endDate: null
  },
  isLoading: false,
  hasError: false,
  hasLoaded: false,
  response: null,
  heatmapsSurveyComparison: {
    response: null,
    error: false,
    loading: false,
    id: "",
    title: "",
    filterError: false,
    heatmapFilters: []
  },
  questionFilter: [],
  heatmapFilters: [],
  heatmapTabSelected: heatMapTabsValues[0].key,
  allHeatmapFilters: [],
  allBusinessAreas: { loading: true, response: null },
  filters: [],
  requiredFilter: null,
  directReports: false,
  isCurrentDirectReports: false,
  searchTerm: "",
  reports: [],
  surveyType: "",
  filteredReports: [],
  reportsToDelete: [],
  reportsToGenerateFrom: [],
  deleteError: false,
  generateError: false,
  isLoadingReports: false,
  hasErrorReports: false,
  hasLoadedReports: false,
  isPdfDownloading: false,
  isHeatMapPdfDownloading: false,
  openModal: false,
  pdfProgress: {
    pageCount: 0,
    isAllImagesGenerated: false,
    pdfGenerated: false,
    completedPages: 0
  },
  generateReportTitle: null,
  redirectLink: ""
}

export const ErrorTypes = {
  generic: "GENERIC",
  ERR100: "ERR100",
  ERR101: "ERR101"
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FULL_HEATMAPS_CLEAR: {
      return {
        ...state,
        heatmapsSurveyComparison: {
          response: null,
          error: false,
          loading: false,
          id: "",
          title: "",
          filterError: false,
          heatmapFilters: []
        },
        heatmapFilters: [],
        allHeatmapFilters: [],
        heatmapTabSelected: heatMapTabsValues[0].key,
        questionFilter: []
      }
    }

    case types.LOAD_HEATMAP_COMPARISON_FAIL: {
      return {
        ...state,
        heatmapsSurveyComparison: {
          ...state.heatmapsSurveyComparison,
          filterError: true
        }
      }
    }

    case types.LOAD_HEATMAP_COMPARISON_SUCCESS: {
      const { filterId, response } = action.payload

      const index = state.heatmapFilters.findIndex(filter => {
        return filter.id === filterId
      })

      if (index >= 0) {
        return {
          ...state,
          heatmapsSurveyComparison: {
            ...state.heatmapsSurveyComparison,
            heatmapFilters: [
              ...state.heatmapsSurveyComparison.heatmapFilters.slice(0, index),
              {
                ...state.heatmapsSurveyComparison.heatmapFilters[index],
                response,
                loading: false
              },
              ...state.heatmapsSurveyComparison.heatmapFilters.slice(index + 1)
            ]
          }
        }
      }

      return state
    }

    case types.SET_HEATMAP_TAB: {
      return {
        ...state,
        heatmapTabSelected: action.payload,
        questionFilter: []
      }
    }
    case types.SET_QUESTION_FILTER: {
      const { questions } = action.payload
      return { ...state, questionFilter: questions }
    }
    case types.COMBINE_HEATMAP_FILTERS: {
      const { dropdownOptions, filterId } = action.payload
      const heatmapFilters = JSON.parse(JSON.stringify(state.heatmapFilters))
      const hfIdx = heatmapFilters.findIndex(obj => obj?.id === filterId)
      const dropdownKeys = Object.keys(dropdownOptions)
      const extractedValuesIdxs = heatmapFilters[hfIdx].heatmapFilter.reduce(
        (acc, next, parentIdx) => {
          next.values.forEach(({ valueId }, valueIdx) => {
            acc[valueId] = { parentIdx, valueIdx }
          })
          return acc
        },
        {}
      )

      const heatmapFilterIdxToRemove = []
      /*
      key = index of heatmapFilter array, value = index of items to delete in
      heatmapFilter[i].value. If value is null, the whole thing object needs to be deleted.
      */
      const deleteHeatmapFilterInstructions = {}

      dropdownKeys.forEach(key => {
        const currentValue = extractedValuesIdxs[key]

        const { selected, data } = dropdownOptions[key]

        if (selected) {
          // 1. ADD DROPDOWN OPTIONS TO BASE HEATMAPFILTERfilterLoading
          /*
          We find if questionId from selected dropdown value exists within heatmapFilter array.
          Then we check If value is not inside heatmapFilter array and we do have a question ID for
          this value inside heatmapFilter, we add only the value to the relevant questionId, else
          we add the whole data (filter) object from the dropdownOptions in heatmapFilter array
          */
          const questionExistsIdx = heatmapFilters[
            hfIdx
          ].heatmapFilter.findIndex(
            ({ questionId }) => questionId === data.questionId
          )

          if (!currentValue && questionExistsIdx !== -1) {
            heatmapFilters[hfIdx].heatmapFilter[questionExistsIdx].values.push(
              data.values[0]
            )
            heatmapFilters[hfIdx].loading = true
            heatmapFilters[hfIdx].response = null
          }

          if (!currentValue && questionExistsIdx === -1) {
            heatmapFilters[hfIdx].heatmapFilter.push(data)
            heatmapFilters[hfIdx].loading = true
            heatmapFilters[hfIdx].response = null
          }

          // 2. REMOVE ADDED DROPDOWN OPTIONS FROM HEATMAPFILTERS ARRAY
          /*
            We need to find the selected dropdownOptions in heatmapFilters state and add
            their indexes for future removal. There might be an instance where the this has
            been done previously, thus not matching the selected dropdownOption in heatmapFilter
            and not needing for any further operation.
          */

          const matchingFilter = findMatchingObject(
            heatmapFilters.filter(
              entry => entry.heatmapFilter.length === 1 && entry.id !== filterId
            ),
            data
          )
          if (matchingFilter) {
            const matchingFilterIdx = heatmapFilters.findIndex(
              ({ id }) => id === matchingFilter.id
            )
            heatmapFilterIdxToRemove.push(matchingFilterIdx)
          }
        } else {
          // Only remove value/add new heatmapFilter if the value is part of the current heatmapFilter
          if (
            currentValue &&
            heatmapFilters[hfIdx].heatmapFilter[currentValue.parentIdx].values
              .length > 1
          ) {
            deleteHeatmapFilterInstructions[currentValue.parentIdx] =
              deleteHeatmapFilterInstructions[currentValue.parentIdx]
                ? [
                    ...deleteHeatmapFilterInstructions[currentValue.parentIdx],
                    currentValue.valueIdx
                  ]
                : [currentValue.valueIdx]
          }

          if (
            currentValue &&
            heatmapFilters[hfIdx].heatmapFilter[currentValue.parentIdx].values
              .length === 1
          ) {
            deleteHeatmapFilterInstructions[currentValue.parentIdx] = null
          }
        }
      })

      let potentialReturnFilter = []
      Object.keys(deleteHeatmapFilterInstructions)
        .reverse()
        .forEach(key => {
          if (deleteHeatmapFilterInstructions[key]) {
            deleteHeatmapFilterInstructions[key].reverse().forEach(entry => {
              if (
                heatmapFilters[hfIdx].heatmapFilter[key].values.length === 1
              ) {
                potentialReturnFilter.push(
                  heatmapFilters[hfIdx].heatmapFilter[key]
                )
                heatmapFilters[hfIdx].heatmapFilter.splice(key, 1)
              } else {
                /* 
                  .splice will modify the original array and return to potentialReturnFilter
                  the required value for checking potential filter
                */
                potentialReturnFilter.push({
                  ...heatmapFilters[hfIdx].heatmapFilter[key],
                  values: heatmapFilters[hfIdx].heatmapFilter[
                    key
                  ].values.splice(entry, 1)
                })
              }
            })
          } else {
            potentialReturnFilter.push(heatmapFilters[hfIdx].heatmapFilter[key])
            heatmapFilters[hfIdx].heatmapFilter.splice(key, 1)
          }
        })

      potentialReturnFilter.map(item => {
        const matchinObjValue = findMatchingValueObj(
          heatmapFilters,
          item.values[0]
        )

        if (!matchinObjValue) {
          heatmapFilters.push({
            heatmapFilter: [item],
            loading: true,
            response: null,
            id: item.values[0].valueId
          })
        }
      })

      // Removal happens at the end not to mess up indexes.
      heatmapFilterIdxToRemove
        .sort(sorterNumberArray)
        .reverse()
        .forEach(idx => {
          heatmapFilters.splice(idx, 1)
        })

      if (state.heatmapsSurveyComparison.id) {
        return {
          ...state,
          heatmapFilters,
          heatmapsSurveyComparison: {
            ...state.heatmapsSurveyComparison,
            heatmapFilters: heatmapFilters.map(filter => ({
              ...filter,
              id: state.heatmapsSurveyComparison.id
            }))
          }
        }
      }

      return { ...state, heatmapFilters }
    }

    case types.ADD_HEATMAP_FILTER: {
      const { filters, appliedFilter } = action.payload
      const heatmapFiltersCopy = JSON.parse(
        JSON.stringify(state.heatmapFilters)
      )

      // Finding the differnce between incoming filters and heatmapfilters applied
      const activeFilters = filters.reduce((acc, next) => {
        next.values.forEach(({ valueId }) => {
          acc.push(valueId)
        })
        return acc
      }, [])

      const valueAndLocation = heatmapFiltersCopy.reduce(
        (acc, next, parentIdx) => {
          next.heatmapFilter.forEach((filter, filterIdx) => {
            filter.values.forEach(({ valueId }, valueIdx) => {
              if (!activeFilters.includes(valueId)) {
                acc[valueId] = { parentIdx, filterIdx, valueIdx }
              }
            })
          })

          return acc
        },
        {}
      )

      // Once differences are found, we sort them out in backwards order in order to remove them later
      const removalOrderArray = Object.keys(valueAndLocation).sort(
        (key1, key2) => {
          const obj1 = data[key1]
          const obj2 = data[key2]

          if (obj1.parentIdx !== obj2.parentIdx) {
            return obj2.parentIdx - obj1.parentIdx
          }

          if (obj1.parentIdx % 2 === 0) {
            if (obj1.filterIdx !== obj2.filterIdx) {
              return obj2.filterIdx - obj1.filterIdx
            }

            if (obj1.filterIdx % 2 === 0) {
              return obj2.valueIdx - obj1.valueIdx
            }
          }

          return 0
        }
      )

      // the removal of filters from active heatmapFilters happens here, along with recalculation if needed
      removalOrderArray.forEach(key => {
        const { parentIdx, filterIdx, valueIdx } = valueAndLocation[key]
        heatmapFiltersCopy[parentIdx].heatmapFilter[filterIdx].values.splice(
          valueIdx,
          1
        )
        if (
          heatmapFiltersCopy[parentIdx].heatmapFilter[filterIdx].values
            .length === 0
        ) {
          heatmapFiltersCopy[parentIdx].heatmapFilter.splice(filterIdx, 1)

          if (heatmapFiltersCopy[parentIdx].heatmapFilter.length === 0) {
            heatmapFiltersCopy.splice(parentIdx, 1)
          }
        }

        if (heatmapFiltersCopy[parentIdx]) {
          heatmapFiltersCopy[parentIdx].loading = true
          heatmapFiltersCopy[parentIdx].response = null
        }
      })

      // Compare the difference between filters coming in and state.heatmapFilters
      // If in heatmapFilters there is a filter thats not available in filters, take it out

      /* Makes sure that a filter is only applied once in the heatmaps, by checking if an 
      incoming filter is already in the state */

      if (findMatchingValueObj(state.heatmapFilters, appliedFilter.values[0]))
        return {
          ...state,
          heatmapFilters: removeDuplicates(heatmapFiltersCopy),
          allHeatmapFilters: filters
        }

      const heatmapFilters = [
        ...heatmapFiltersCopy,
        {
          heatmapFilter: [appliedFilter],
          loading: true,
          response: null,
          id: appliedFilter.values[0].valueId
        }
      ]

      if (state.heatmapsSurveyComparison.id) {
        return {
          ...state,
          heatmapsSurveyComparison: {
            ...state.heatmapsSurveyComparison,
            heatmapFilters: heatmapFilters.map(filter => ({
              ...filter,
              id: state.heatmapsSurveyComparison.id
            }))
          },
          heatmapFilters,
          allHeatmapFilters: filters
        }
      }

      return {
        ...state,
        heatmapFilters,
        allHeatmapFilters: filters
      }
    }
    case types.CLEAR_ALL_HEATMAP_FILTERS:
      return { ...state, heatmapFilters: [], allHeatmapFilters: [] }
    case types.SET_ALL_BUSINESS_AREAS: {
      return {
        ...state,
        allBusinessAreas: action.payload.allBusinessAreas
      }
    }
    case types.SET_SUB_CHECKED_LIST:
      return {
        ...state,
        surveyComparison: {
          ...state.surveyComparison,
          subCheckedList: action.payload
        }
      }

    case types.SET_SURVEY_COMPARISON_END_DATE:
      return {
        ...state,
        surveyComparison: {
          ...state.surveyComparison,
          endDate: action.payload
        }
      }

    case types.SET_SURVEY_COMPARISON_START_DATE:
      return {
        ...state,
        surveyComparison: {
          ...state.surveyComparison,
          startDate: action.payload
        }
      }
    case types.SET_COMPARISON_DATASET:
      return {
        ...state,
        surveyComparison: {
          ...state.surveyComparison,
          dataset: action.payload
        }
      }

    case types.GET_SURVEY_COMPARISON_DATA_FAIL:
      return {
        ...state,
        hasError: action.payload,
        isLoading: false
      }

    case types.GET_SURVEY_COMPARISON_DATA_SUCCESS:
      return {
        ...state,
        surveyComparison: {
          ...state.surveyComparison,
          data: action.payload
        },
        isLoading: false,
        hasError: false
      }

    case types.GET_SURVEY_COMPARISON_DATA:
      return {
        ...state,
        isLoading: true
      }

    case types.SET_SURVEY_COMPARISON_CHECKED_LIST:
      return {
        ...state,
        surveyComparison: {
          ...state.surveyComparison,
          checkedList: action.payload
        }
      }

    case types.SET_SURVEY_COMPARISON_BREADCRUMBS:
      return {
        ...state,
        surveyComparison: {
          ...state.surveyComparison,
          breadcrumbs: action.payload
        }
      }

    case types.SET_SURVEY_COMPARISON_STAGE:
      return {
        ...state,
        surveyComparison: {
          ...state.surveyComparison,
          stage: action.payload
        }
      }

    case types.LOAD_REPORTS:
      return {
        ...state,
        isLoadingReports: true,
        redirectLink: null,
        hasError: false
      }

    case types.SET_HAS_ERROR:
      return { ...state, hasError: false, isLoading: false }

    case types.EMPTY_REPORT_LIST:
      return { ...state, hasError: true }

    case types.CREATE_LATEST_REPORT:
      return { ...state, isLoading: true }

    case types.CREATE_LATEST_REPORT_SUCCESS:
      return { ...state, redirectLink: action.payload, isLoading: false }

    case types.CREATE_LATEST_REPORT_FAIL:
      return { ...state, hasError: action.payload, isLoading: false }

    case types.SET_SURVEY_TYPE:
      return { ...state, surveyType: action.payload }

    case types.SET_SEARCH_TERM:
      return { ...state, searchTerm: action.payload }

    case types.SET_DELETE_REPORT:
      return { ...state, reportsToDelete: action.payload }

    case types.SET_GENERATE_REPORT:
      return { ...state, reportsToGenerateFrom: action.payload }

    case types.DELETE_REPORTS_FAIL:
      return { ...state, deleteError: action.payload, isLoading: false }

    case types.DELETE_REPORTS_SUCCESS:
      return { ...state, reportsToDelete: [], isLoading: false }

    case types.DELETE_REPORTS:
      return { ...state, isLoading: true }

    case types.GENERATE_REPORT:
      return { ...state, isLoading: true }

    case types.GENERATE_REPORT_SUCCESS:
      return {
        ...state,
        reportsToGenerateFrom: [],
        generateError: false,
        redirectLink: action.payload,
        isLoading: false
      }

    case types.GENERATE_REPORT_FAIL:
      return {
        ...state,
        reportsToGenerateFrom: [],
        generateError: action.payload,
        isLoading: false
      }

    case types.SET_OPEN_MODAL:
      return { ...state, openModal: action.payload }

    case types.SET_GENERATE_REPORT_TITLE:
      return {
        ...state,
        generateReportTitle: action.payload
      }

    case types.RETRY_LOAD_REPORTS:
      return {
        ...state,
        isLoading: true,
        isLoadingReports: true,
        hasLoadedReports: false,
        hasErrorReports: false,
        redirectLink: null
      }

    case types.SET_REQUIRED_FILTER:
      return { ...state, requiredFilter: action.payload }

    case types.SET_FILTERED_REPORTS:
      return { ...state, filteredReports: action.payload }

    case types.LOAD_REPORTS_SUCCESS:
      return {
        ...state,
        reports: action.payload,
        isLoading: false,
        isLoadingReports: false,
        hasLoadedReports: true,
        hasErrorReports: false,
        redirectLink: null,
        filters: []
      }

    case types.UPDATE_DIRECT_REPORTS:
      return {
        ...state,
        directReports: action.payload
      }

    case types.LOAD_REPORTS_FAIL:
      return {
        ...state,
        isLoading: false,
        isLoadingReports: false,
        hasErrorReports: true,
        redirectLink: null
      }

    case types.LOAD:
      return {
        ...state,
        isLoading: true,
        hasLoaded: false,
        hasError: false,
        response: null
      }

    case types.RETRY_LOAD:
      return {
        ...state,
        isLoading: true,
        response: action.report,
        hasLoaded: false,
        hasError: false
      }

    case types.LOAD_SUCCESS:
      return {
        ...state,
        response: action.payload,
        isCurrentDirectReports: !!action.payload.isDirectReport,
        isLoading: false,
        hasLoaded: true,
        filters: action.payload?.filters?.applied || [],
        redirectLink: null
      }

    case types.LOAD_COMPARISON:
      return {
        ...state,
        heatmapsSurveyComparison: {
          response: null,
          error: false,
          loading: true,
          id: action.payload.surveyId,
          title: action.payload.title
        }
      }

    case types.COMPARISON_FAIL:
      return {
        ...state,
        heatmapsSurveyComparison: {
          ...state.heatmapsSurveyComparison,
          response: null,
          error: true,
          loading: false
        }
      }

    case types.COMPARISON_SUCCESS:
      return {
        ...state,
        heatmapsSurveyComparison: {
          ...state.heatmapsSurveyComparison,
          response: action.payload,
          error: false,
          loading: false
        }
      }

    case types.LOAD_ALL_BUSINESS_AREAS_SUCCESS:
      return {
        ...state,
        allBusinessAreas: {
          response: action.response,
          loading: false,
          error: null
        }
      }
    case types.HANDLE_ALL_BUSINESS_AREAS_ERROR:
      return {
        ...state,
        allBusinessAreas: {
          response: null,
          loading: false,
          error: action.payload
        }
      }
    case types.LOAD_HEATMAP_FILTER_DATA_SUCCESS: {
      const { filterId, response } = action
      const index = state.heatmapFilters.findIndex(filter => {
        return filter.id === filterId
      })
      if (index >= 0) {
        return {
          ...state,
          heatmapFilters: [
            ...state.heatmapFilters.slice(0, index),
            {
              ...state.heatmapFilters[index],
              response,
              loading: false
            },
            ...state.heatmapFilters.slice(index + 1)
          ]
        }
      }
      return state
    }
    case types.HANDLE_LOAD_HEATMAP_FILTER_DATA_ERRORS:
      return {
        ...state,
        allBusinessAreas: {
          response: null,
          loading: false,
          error: action.payload
        }
      }
    case types.LOAD_FAIL:
      return {
        ...state,
        isLoading: false,
        hasError: ErrorTypes.generic,
        redirectLink: null
      }

    case types.HANDLE_SURVEY_ERRORS: {
      const res = {
        ...state,
        isLoading: false,
        hasError: ErrorTypes[action.payload.code] || ErrorTypes.generic,
        filters: []
      }
      if (action.payload.survey) {
        res.response = action.payload.survey
      }
      return res
    }

    case types.UPDATE_FILTERS: {
      const sortedFilters = action.payload
        .map(filter => {
          filter.values.sort((a, b) => a.value.localeCompare(b.value))
          return filter
        })
        .sort((a, b) => a.question.localeCompare(b.question))
      return {
        ...state,
        filters: sortedFilters,
        hasError: false
      }
    }

    case types.UPDATE_PDF_DOWNLOADING:
      return {
        ...state,
        isPdfDownloading: action.payload,
        pdfProgress: action.payload
          ? state.pdfProgress
          : initialState.pdfProgress
      }
    case types.UPDATE_HEATMAP_PDF_DOWNLOADING:
      return {
        ...state,
        isHeatMapPdfDownloading: action.payload,
        pdfProgress: action.payload
          ? state.pdfProgress
          : initialState.pdfProgress
      }

    case types.UPDATE_PDF_PROGRESS:
      return {
        ...state,
        pdfProgress: { ...state.pdfProgress, ...action.payload }
      }
    case types.RESET_PDF_PROGRESS:
      return {
        ...state,
        pdfProgress: initialState.pdfProgress
      }
    default:
      return state
  }
}
