import { Flex, Text } from "@engaging-tech/components"
import React from "react"
import styled from "styled-components"
import { StyledTextField } from "../../../../ui/components/StyledTextField"

const Img = styled.img``
const crossedTools = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/crossed_tools.svg`)

const StepInput = ({ errorMsg, setLocalText }) => {
  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      flexWrap="wrap"
      flexDirection="column"
    >
      <Flex position="relative" width={4 / 5}>
        <StyledTextField
          noLabel
          borderRadius={2}
          width={1 / 1}
          height={35}
          alignItems="center"
          onChange={e => setLocalText(e.target.value)}
        />
        {errorMsg && (
          <Text
            width={1 / 1}
            position="absolute"
            bottom="-25px"
            left={0}
            bg="dark.4"
            fontSize={2}
            p="4px 8px"
            border="1px solid red"
          >
            Error: {errorMsg}
          </Text>
        )}
      </Flex>
      <Img src={crossedTools} alt="crossed tools" />
    </Flex>
  )
}

export default StepInput
