import { Flex, Text } from "@engaging-tech/components"
import { Item } from "@engaging-tech/components/dist/lib/List/List"
import React from "react"
import styled from "styled-components"

const List = styled.ul`
  margin-top: 2px;
  padding-left: ${({ pl }) => pl};
  margin: ${({ m }) => m};
`

const SuggestedPhrases = ({ suggestedPhrases }) => (
  <Flex flexWrap="wrap" bg="#00E96E1A">
    <Text fontWeight={600} pl="10px" my={3}>
      Suggested Phrases:
    </Text>
    <List>
      {suggestedPhrases?.map(({ phrase, id }) => (
        <Item key={id}>{phrase}</Item>
      ))}
    </List>
  </Flex>
)

export default SuggestedPhrases
