import { Text, userInterfaceNotificationsStore } from "@engaging-tech/components"
import { useParams } from "@engaging-tech/routing"
import { Form, Formik } from "formik"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import useBusinessAppMutation from "../../../hooks/useBusinessAppMutation"
import CreationFormsLayout from "../../../layouts/CreationFormsLayout"
import { loadSurvey } from "../survey-view-mode/store/survey-view-mode.actions"
import { getIntroduction, getSurvey } from "../survey-view-mode/store/survey-view-mode.selectors"
import BottomNav from "./components/bottom-nav"
import ConfirmDiscardModal from "./components/confirm-discard-modal"
import MainForm from "./main-form"
import { UPDATE_SURVEY } from "./services/survey-edit-mode.queries"

const workLLogo = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/WorkL_Logo_TEAL.png`)

export default function SurveyEditMode() {
  const history = useHistory()
  const { id, status } = useParams()

  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)

  const survey = useSelector(getSurvey)
  const surveyIntroduction = useSelector(getIntroduction)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadSurvey(id))
  }, [])

  const { post } = useBusinessAppMutation(UPDATE_SURVEY, {
    onSuccess: () => {
      dispatch(
        userInterfaceNotificationsStore.actions.addNotification({ message: "Survey updated successfully!" })
      )
      history.push(`/surveys/${id}/${status}/view`)
    },
    onError: () => {
      dispatch(userInterfaceNotificationsStore.actions.addNotification({ message: "Error updating survey!" }))
    }
  })

  const handleSubmit = async values => {
    const descriptionLines = values.introduction.match(/[^\r\n]+/g) || []

    let data = {
      id,
      title: values.title,
      introduction: descriptionLines.map(d => ({
        type: "paragraph",
        content: d
      })),
      templateId: values.template.id
    }

    if (values.status === "draft") {
      data = {
        ...data,
        meta: {
          private: values.private,
          startDate: values.startDate,
          endDate: values.endDate,
          brand: {
            colour: values.colour,
            logo: values.logo
          }
        }
      }
    } else if (values.status === "scheduled") {
      data = {
        ...data,
        meta: {
          private: values.private,
          startDate: values.startDate,
          endDate: values.endDate,
          brand: {
            colour: values.colour,
            logo: values.logo
          }
        }
      }
    } else {
      data = {
        ...data,
        meta: {
          private: values.private,
          endDate: values.endDate,
          brand: {
            colour: values.colour,
            logo: values.logo
          }
        }
      }
    }

    delete data.id
    post({ surveyId: id, survey: data })
  }

  return (
    <>
      <Formik
        onSubmit={handleSubmit}
        initialValues={{
          id,
          status: survey?.status,
          title: survey?.title ?? "",
          introduction: survey ? surveyIntroduction : "",
          template: survey ? survey.template : {},
          startDate: survey?.meta?.startDate,
          endDate: survey?.meta?.endDate,
          colour: survey?.meta?.brand?.colour ?? "#013e4c",
          logo: survey?.meta?.brand?.logo ?? workLLogo,
          private: survey?.meta?.private ?? false
        }}
        // validationSchema={object().shape({
        //   title: string().required("A title is required for the survey"),
        //   introduction: string(),
        //   template: object()
        //     .shape({
        //       id: string().required("A template must be selected")
        //     })
        //     .required("A template must be selected")
        // })}
        enableReinitialize
      >
        {props => (
          <Form>
            <CreationFormsLayout
              bg="#EEF4F9"
              maxWidth="1000px"
              mx={20}
              mb={7}
              BottomNav={() => (
                <BottomNav onCancel={() => setIsCancelModalOpen(true)} onClose={() => history.goBack()} />
              )}
            >
              <Text mt="20px" fontSize={["18px", 5]} mb={4} fontWeight={700}>
                Your survey is in edit mode
              </Text>
              <Text>
                You are now in edit mode and can update any of the below survey information. Select the Save
                and close button when you are happy with your changes.
              </Text>

              <MainForm {...props} surveyType={survey?.type} />
            </CreationFormsLayout>
          </Form>
        )}
      </Formik>

      {isCancelModalOpen ? (
        <ConfirmDiscardModal
          onConfirm={() => history.push(`/surveys/${id}/${survey.status}/view`)}
          onCancel={() => setIsCancelModalOpen(false)}
        />
      ) : null}
    </>
  )
}
