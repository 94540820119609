import React from "react"
import { Flex, H2, Button, ImageIcon, Box } from "@engaging-tech/components"
import { useSelector } from "react-redux"
import { usePaths, useRouter } from "@engaging-tech/routing"
import { ReportSelectors } from ".."
import EmptyDashboard from "../components/EmptyDashboard"
import OrganisationReportListContainer from "../EmployeeReports/containers/OrganisationReportListContainer"

const newIcon = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/newIcon.svg`)

const ReportsDashboard = () => {
  const hasData = useSelector(ReportSelectors.hasData)
  const paths = usePaths()
  const router = useRouter()

  return (
    <Flex flexDirection="column">
      <Flex
        flexDirection={["column", "row"]}
        width={1 / 1}
        justifyContent="space-between"
        mb={[3, 0]}
        height={["80px", "60px"]}
      >
        <H2 fontWeight={700} fontSize={[5, 6]} width={[1 / 1, "fit-content"]}>
          Engagement Reports
        </H2>

        <Box mt={[20, 0]} width="fit-content">
          <Button
            width="fit-content"
            variant="outline.primary.0"
            color="light.0"
            onClick={() => {
              router.navigate(
                paths.reports.engagementSurveys.compareEngagementSurveys
              )
            }}
          >
            <ImageIcon src={newIcon} width={40} name="compare" /> Compare Survey
            Results
          </Button>
        </Box>
      </Flex>
      {hasData ? (
        <OrganisationReportListContainer
          checkBoxLabelText="Language BreakDown"
          surveyDetails={{
            title: "Engagement Survey",
            createLink: "engagement-surveys"
          }}
          showCheckBox={isRoot => false}
        />
      ) : (
        <EmptyDashboard />
      )}
    </Flex>
  )
}

export default ReportsDashboard
