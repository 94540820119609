import React, { createContext, useContext } from "react"
import { useParams, usePaths } from "@engaging-tech/routing"
import { useSelector } from "react-redux"
import { Box } from "@engaging-tech/components"
import * as ReportSelectors from "../store/report.selectors"
import ReportHeader from "../Header/containers/ReportHeader"
import BackButton from "../components/BackButton"
import { ReportLayout } from "../components/ReportLayout"
import { Tabs } from "../../ui/components/Tabs/Tabs"
import { useQueryParams } from "../../../hooks/useQueryParams"

const ActionPlanContext = createContext();

const actionPlanTabComponents = ["sixStepsActionPlans"];

const getTabs = (components) => [
  { title: "Data", matches: components.filter(component => !actionPlanTabComponents.includes(component)) },
  { title: "Action Plan", matches: components.filter(component => actionPlanTabComponents.includes(component)) },
];

const ActionPlanProvider = ({ children }) => {
  const components = useSelector(ReportSelectors.getSixStepsScoresChildrenLayout);
  console.log({components})

  const tabs = getTabs(components);
  const { query, setQueryParam } = useQueryParams();

  const activeTab = query.get("tab") || (tabs.length > 0 ? tabs[0].title : "");
  const tag = query.get("tag") || "";

  const isSixStepScores = tag === "sixStepsScores";

  const setActiveTab = tab => {
    setQueryParam("tab", tab);
  };

  return (
    <ActionPlanContext.Provider value={{ components, tabs, activeTab, isSixStepScores, setActiveTab }}>
      {children}
    </ActionPlanContext.Provider>
  );
};

const ActionPlan = () => {
  const { id, step, surveyType } = useParams();
  const paths = usePaths();

  return (
    <ActionPlanProvider>
      <BackButton
        path={paths.reports.summary.index
          .replace(":surveyType", surveyType)
          .replace(":id", id)}
      />
      <ActionPlanContent step={step} />
    </ActionPlanProvider>
  );
};

const ActionPlanContent = ({ step }) => {
  const { components, tabs, activeTab, isSixStepScores, setActiveTab } = useContext(ActionPlanContext);

  return (
    <>
      <ReportHeader
        subSection="Six Steps to Workplace Engagement Scores"
        reportComponents={components}
      />
      {isSixStepScores && (
        <Box width={1}>
          <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
        </Box>
      )}
      <ReportLayout components={components} step={step} isSixStepScores={isSixStepScores} tabs={tabs} />
    </>
  );
};

export default ActionPlan;
