import { Box, Card, Flex, H2, H3, Icon, IconButton, Paragraph, Text } from "@engaging-tech/components"
import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"

import UpgradedLabel from "../../ui/components/UpgradedLabel"

const HeadingImage = styled.img`
  object-fit: contain;
  max-width: 48px;
  max-height: 48px;
  height: 48px;
  width: 48px;
  margin-right: 14px;
`

const Tooltip = ({ tooltip, setShowTooltip, ...props }) => (
  <Card
    borderRadius="10px"
    p={3}
    bg="white"
    flexDirection="column"
    elevation={2}
    position="absolute"
    zIndex={2}
    width={[250, 500]}
    {...props}
  >
    <Flex width={1 / 1} justifyContent="space-between">
      <H3 fontWeight="bold" mb={3} fontSize={[4, 6]}>
        {tooltip.header}
      </H3>
      <IconButton icon="close" onClick={() => setShowTooltip(false)} size={24} color="dark.2" />
    </Flex>
    <Paragraph width="auto" color="dark.3" fontSize={4}>
      {tooltip.text}
    </Paragraph>
  </Card>
)

const ReportCard = ({
  width = [1 / 1, 1 / 1, 39 / 100],
  children,
  heading,
  exportingPDF,
  headingImage,
  headingIcon,
  HeadingImageComponent,
  headingIconProps,
  props,
  metaProps,
  headingProps,
  tooltip,
  tooltipProps,
  innerProps,
  isPdfDownloading,
  showUpgradeLabel,
  margin = null
}) => {
  const [showTooltip, setShowTooltip] = useState(false)
  const [iconLeftPosition, setIconLeftPosition] = useState(0)
  const containerRef = useRef()
  useEffect(() => {
    if (containerRef.current) {
      // get the position of the icon in relation to parent
      setIconLeftPosition(containerRef.current.offsetLeft)
    }
  }, [])

  return (
    <Card
      width={exportingPDF ? "1000px" : width}
      p={3}
      style={{ flexGrow: 1 }}
      justifyContent="flex-start"
      flexDirection="column"
      elevation={exportingPDF || isPdfDownloading ? 0 : 2}
      alignItems="center"
      m={margin || 2}
      position="relative"
      className={exportingPDF ? "report-item-exporting" : "report-item"}
      bg="rgba(255, 255, 255, 1.0)"
      {...props}
    >
      <Flex flexDirection="column" alignItems="center" width={1 / 1} height="100%" {...innerProps}>
        <Flex alignItems="center" mb={4} {...metaProps}>
          {HeadingImageComponent && (
            <Flex width="auto" height="auto" mr={3}>
              <HeadingImageComponent />
            </Flex>
          )}
          {headingImage && <HeadingImage src={headingImage} />}
          {headingIcon && <Icon name={headingIcon} {...headingIconProps} />}
          {heading && (
            <>
              <H2 color="dark.1" width="auto" fontWeight="700" fontSize={5} {...headingProps}>
                {heading}
              </H2>
              {showUpgradeLabel && <UpgradedLabel />}
            </>
          )}
          {tooltip && (
            <Box width="auto" ref={containerRef}>
              <IconButton
                onClick={() => setShowTooltip(!showTooltip)}
                icon="information"
                size={24}
                iconColor="dark.3"
              />
            </Box>
          )}
        </Flex>
        {children}
      </Flex>
      {showTooltip && (
        <Tooltip
          setShowTooltip={setShowTooltip}
          tooltip={tooltip}
          left={["unset", "unset", iconLeftPosition + 40]}
          {...tooltipProps}
        />
      )}
    </Card>
  )
}

export default ReportCard
