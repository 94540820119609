import { Flex, Text } from "@engaging-tech/components"
import { Item } from "@engaging-tech/components/dist/lib/List/List"
import React from "react"
import styled from "styled-components"

const List = styled.ul`
  margin-top: 2px;
  padding-left: ${({ pl }) => pl};
  margin: ${({ m }) => m};
`

const Action = ({ action: { paragraph, subPoints } }) => (
  <Flex flexWrap="wrap" mb={4}>
    <Item dangerouslySetInnerHTML={{ __html: paragraph }} />
    <List>
      {subPoints?.map(({ point }) => (
        <Item key={point}>{point}</Item>
      ))}
    </List>
  </Flex>
)

const SuggestedActions = ({ suggestedActions }) => (
  <Flex flexWrap="wrap" bg="secondary.2" mb={4}>
    <Text fontWeight={600} my={3} pl="10px">
      Suggested Actions:
    </Text>
    <List pl="20px" m="10px">
      {suggestedActions?.map(action => (
        <Action key={action?.id} action={action} />
      ))}
    </List>
  </Flex>
)

export default SuggestedActions
