import { connect } from "react-redux"
import { getCurrentStepScore } from "../../SixStepsOverview/store/sixstepsoverview.selectors"
import CircularScoreComponent from "../components/SixStepsPagesComponents/CircularScore"

const mapState = (state, { step }) => ({
  score: getCurrentStepScore(state, step)
})

const CircularScore = connect(mapState)(CircularScoreComponent)

export default CircularScore
