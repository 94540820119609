/* eslint-disable prefer-destructuring */
import { Flex } from "@engaging-tech/components"
import React from "react"

import StepQuestionSumResultBlock from "./StepQuestionSumResultBlock"

const SixStepColumn = ({
  allBusinessAreas,
  heatmapFilters,
  allSteps,
  sixStepsComparison,
  showGlobalResult,
  firstGroupAndQuestion,
  comparisonReport,
  isComparisonLoading,
  comparisonSurveyTitle,
  showPreviousComp,
  comparisonFilters,
  questionGroup
}) => {
  const sixStepsComparisonSteps = sixStepsComparison.map(({ name }) => name)
  const allBusinessloading = allBusinessAreas.loading
  let allBusinessData = null
  if (allBusinessAreas.response?.data) {
    allBusinessData = allBusinessAreas.response?.data
  }

  const combinedSteps = [
    ...(sixStepsComparison ?? []),
    ...allSteps.filter(({ step }) => !sixStepsComparisonSteps.includes(step))
  ]

  const comparisonCombinedSteps = [
    ...(comparisonReport?.data?.sixStepsComparison ?? []),
    ...(comparisonReport?.data?.customSteps ?? [])
  ]

  const foundParentScore = combinedSteps.filter(
    entry =>
      entry?.step === questionGroup.step || entry?.name === questionGroup.step
  )

  const foundComparisonScore = comparisonCombinedSteps.filter(
    entry =>
      entry?.step === questionGroup.step || entry?.name === questionGroup.step
  )

  const calcSum = (step, key) => {
    let score = 0
    step?.questions?.forEach(question => {
      score += question.values[key]
    })

    const average = score / step?.questions?.length

    return average
  }

  return (
    <Flex borderRadius={5} width={150} p={2} flexDirection="column">
      {allBusinessloading && (
        <>
          <StepQuestionSumResultBlock
            loading
            sumAverageScore={null}
            nodata={false}
            labelToRender="Your Score"
            renderLabel={showGlobalResult || showPreviousComp}
            firstGroupAndQuestion={firstGroupAndQuestion}
          />

          {showGlobalResult ? (
            <>
              <StepQuestionSumResultBlock
                loading
                sumAverageScore={null}
                nodata={false}
                labelToRender="Industry"
                renderLabel={showGlobalResult}
                firstGroupAndQuestion={firstGroupAndQuestion}
              />
              <StepQuestionSumResultBlock
                loading
                sumAverageScore={null}
                nodata={false}
                labelToRender="Global"
                renderLabel={showGlobalResult}
                firstGroupAndQuestion={firstGroupAndQuestion}
              />
            </>
          ) : null}
        </>
      )}
      {!allBusinessloading && allBusinessData && (
        <>
          <StepQuestionSumResultBlock
            loading={false}
            sumAverageScore={
              !!foundParentScore.length && foundParentScore[0]?.score
            }
            nodata={false}
            labelToRender="Your Score"
            renderLabel={showGlobalResult || showPreviousComp}
            firstGroupAndQuestion={firstGroupAndQuestion}
          />
          {showGlobalResult ? (
            <>
              <StepQuestionSumResultBlock
                loading={false}
                sumAverageScore={
                  !!foundParentScore.length && foundParentScore[0]?.industry
                }
                nodata={
                  !!foundParentScore.length && foundParentScore[0]?.industry
                    ? false
                    : true
                }
                labelToRender="Industry"
                renderLabel={showGlobalResult}
                firstGroupAndQuestion={firstGroupAndQuestion}
              />
              <StepQuestionSumResultBlock
                loading={false}
                sumAverageScore={
                  !!foundParentScore.length && foundParentScore[0]?.global
                }
                nodata={
                  !!foundParentScore.length && foundParentScore[0]?.global
                    ? false
                    : true
                }
                labelToRender="Global"
                renderLabel={showGlobalResult}
                firstGroupAndQuestion={firstGroupAndQuestion}
              />
            </>
          ) : null}
        </>
      )}

      {isComparisonLoading && (
        <StepQuestionSumResultBlock
          loading
          sumAverageScore={null}
          nodata={false}
          renderLabel={isComparisonLoading}
          firstGroupAndQuestion={firstGroupAndQuestion}
          labelToRender={"Previous Data" || comparisonSurveyTitle}
        />
      )}
      {comparisonReport && showPreviousComp && (
        <StepQuestionSumResultBlock
          loading={false}
          sumAverageScore={
            !!foundComparisonScore.length && foundComparisonScore[0]?.score
          }
          nodata={!foundComparisonScore.length}
          renderLabel={!!comparisonReport}
          firstGroupAndQuestion={firstGroupAndQuestion}
          labelToRender={"Previous Data" || comparisonSurveyTitle}
        />
      )}

      {heatmapFilters.map(filter => {
        const comparisonFilter = comparisonFilters.filter(
          compFilter =>
            JSON.stringify(filter.heatmapFilter) ===
            JSON.stringify(compFilter.heatmapFilter)
        )
        if (
          (showPreviousComp && filter.loading) ||
          (showPreviousComp && comparisonFilter[0].loading)
        ) {
          return (
            <>
              <StepQuestionSumResultBlock
                key={filter.id}
                loading={true}
                sumAverageScore={null}
                nodata={false}
                labelToRender="Your Score"
                renderLabel={true}
                firstGroupAndQuestion={firstGroupAndQuestion}
              />

              <StepQuestionSumResultBlock
                key={`${filter.id}-${comparisonFilter[0].id}`}
                loading={true}
                sumAverageScore={null}
                nodata={false}
                labelToRender={"Previous Data" || comparisonSurveyTitle}
                renderLabel={true}
                firstGroupAndQuestion={firstGroupAndQuestion}
              />
            </>
          )
        } else if (filter.loading) {
          return (
            <>
              <StepQuestionSumResultBlock
                loading
                sumAverageScore={null}
                nodata={false}
                labelToRender="Your Score"
                renderLabel={showGlobalResult}
                firstGroupAndQuestion={firstGroupAndQuestion}
              />
              {showGlobalResult ? (
                <>
                  <StepQuestionSumResultBlock
                    loading
                    sumAverageScore={null}
                    nodata={false}
                    labelToRender="Industry"
                    renderLabel={showGlobalResult}
                    firstGroupAndQuestion={firstGroupAndQuestion}
                  />
                  <StepQuestionSumResultBlock
                    loading
                    sumAverageScore={null}
                    nodata={false}
                    labelToRender="Global"
                    renderLabel={showGlobalResult}
                    firstGroupAndQuestion={firstGroupAndQuestion}
                  />
                </>
              ) : null}
            </>
          )
        }
        if (filter.response?.data?.sixStepsQuestions?.length > 0) {
          const filteredSteps = [
            ...(filter?.response?.data?.sixStepsQuestions ?? []),
            ...(filter?.response?.data?.customSteps ?? [])
          ]

          const foundMainStep = filteredSteps.filter(
            entry =>
              entry?.step === questionGroup.step ||
              entry?.name === questionGroup.step
          )

          const allComparisonSteps = [
            ...(comparisonFilter[0]?.response.data.sixStepsQuestions ?? []),
            ...(comparisonFilter[0]?.response.data.customSteps ?? [])
          ]

          const foundComparisonStep = allComparisonSteps.filter(
            entry =>
              entry?.step === questionGroup.step ||
              entry?.name === questionGroup.step
          )

          const isComparisonStepComparible =
            foundMainStep.length &&
            foundMainStep[0]?.questions.some(question => {
              const foundComparisonStepQuestionIds =
                foundComparisonStep.length &&
                foundComparisonStep[0]?.questions.map(({ id }) => id)

              if (
                foundComparisonStepQuestionIds &&
                foundComparisonStepQuestionIds?.includes(question.id)
              )
                return true
              return false
            })

          const compSum = calcSum(foundComparisonStep[0], "score")

          return (
            <>
              <StepQuestionSumResultBlock
                loading={false}
                sumAverageScore={calcSum(foundMainStep[0], "score")}
                nodata={false}
                labelToRender="Your Score"
                renderLabel={showGlobalResult || showPreviousComp}
                firstGroupAndQuestion={firstGroupAndQuestion}
              />

              {showPreviousComp && (
                <StepQuestionSumResultBlock
                  loading={false}
                  sumAverageScore={compSum}
                  nodata={!isComparisonStepComparible || !compSum}
                  labelToRender="Previous Score"
                  renderLabel={showPreviousComp}
                  firstGroupAndQuestion={firstGroupAndQuestion}
                />
              )}
              {showGlobalResult ? (
                <>
                  <StepQuestionSumResultBlock
                    loading={false}
                    sumAverageScore={calcSum(foundMainStep[0], "industry")}
                    nodata={!calcSum(foundMainStep[0], "industry")}
                    labelToRender="Industry"
                    renderLabel={showGlobalResult}
                    firstGroupAndQuestion={firstGroupAndQuestion}
                  />
                  <StepQuestionSumResultBlock
                    loading={false}
                    sumAverageScore={calcSum(foundMainStep[0], "global")}
                    nodata={!calcSum(foundMainStep[0], "global")}
                    labelToRender="Global"
                    renderLabel={showGlobalResult}
                    firstGroupAndQuestion={firstGroupAndQuestion}
                  />
                </>
              ) : null}
            </>
          )
        }
        return (
          <>
            <StepQuestionSumResultBlock
              loading={false}
              sumAverageScore={null}
              nodata
              labelToRender="Your Score"
              renderLabel={showGlobalResult}
              firstGroupAndQuestion={firstGroupAndQuestion}
            />
            {showPreviousComp && (
              <StepQuestionSumResultBlock
                loading={false}
                sumAverageScore={null}
                nodata
                labelToRender="Previous Score"
                renderLabel={showPreviousComp}
                firstGroupAndQuestion={firstGroupAndQuestion}
              />
            )}
            {showGlobalResult ? (
              <>
                <StepQuestionSumResultBlock
                  loading={false}
                  sumAverageScore={null}
                  nodata
                  labelToRender="Industry"
                  renderLabel={showGlobalResult}
                  firstGroupAndQuestion={firstGroupAndQuestion}
                />
                <StepQuestionSumResultBlock
                  loading={false}
                  sumAverageScore={null}
                  nodata
                  labelToRender="Global"
                  renderLabel={showGlobalResult}
                  firstGroupAndQuestion={firstGroupAndQuestion}
                />
              </>
            ) : null}
          </>
        )
      })}
    </Flex>
  )
}

export default SixStepColumn
