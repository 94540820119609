import { connect } from "react-redux"
import { getAllReports } from "../../store/report.selectors"
import EmployeeReportsList from "../components/EmployeeReportsList"

const mapState = state => ({
  reports: getAllReports(state),
  selectedReports: []
})

const OrganisationReportListContainer = connect(mapState)(EmployeeReportsList)

export default OrganisationReportListContainer
