import React from "react"
import { Text, Flex, Box, Paragraph } from "@engaging-tech/components"
import IconCircle from "../../ui/components/CircleIcon"

const image = require(`${process.env.RAZZLE_PUBLIC_DIR}/img/Jobs_Empty_State.png`)

const EmptyJobState = () => (
  <Box bg="dark.7" m={[3, 0]} p={[3, 5]}>
    <Flex flexDirection="column" width="auto" mx={[0, 4]}>
      <Flex justifyContent="center" width="100%" mb={[0, 5]}>
        <Paragraph
          color="dark.1"
          fontWeight={500}
          width={[1 / 1, 1 / 2]}
          textAlign="center"
          fontSize={4}
        >
          Target active job seekers and find the best-matched candidates.
        </Paragraph>
      </Flex>
      <Flex flexDirection={["column", "row"]}>
        <Flex flexDirection="column" width={[1 / 1, 1 / 2]}>
          <Flex flexDirection="row" width={1 / 1} my={[3, 5]}>
            <IconCircle name="briefcase" mr={3} />
            <Text color="dark.1" fontSize={4} fontWeight={500} width={1 / 1}>
              Post unlimited job vacancies on the WorkL Jobs Marketplace.
            </Text>
          </Flex>
          <Flex flexDirection="row" width={1 / 1}>
            <IconCircle name="account_circle" mr={3} />
            <Text color="dark.1" fontSize={4} fontWeight={500} width={1 / 1}>
              Our Job Matcher service is an ultra-efficient candidate match,
              notification and application process.
            </Text>
          </Flex>
        </Flex>
        <Flex width={[1 / 1, 1 / 2]} my={[4, 0]} justifyContent="center">
          <img width={["100%", "369px"]} src={image} alt="Empty Jobs Table" />
        </Flex>
      </Flex>
    </Flex>
  </Box>
)

export default EmptyJobState
